
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useContext, useEffect, useState } from "react"
import { FaCode, FaEdit, FaEye, FaEyeSlash, FaLink, FaPlus, FaTrash } from "react-icons/fa"
import { Link, useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { useCourseId } from "hooks/router/useUrlParams"
import { LessonsContext } from "../contexts/LessonsContextCSBridge"


export const LessonEditorCSBridge = () => {

  const { courseId } = useParams()
  const navigate = useNavigate()

  const { lessons, lessonsLoading } = useContext(LessonsContext)


  return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <div className='container-lg'>
      <h2 className='mt-3'>
        Lesson Editor
      </h2>
      {!lessonsLoading && <LessonEditorMain lessons={lessons} />}
    </div>
  </div>
}

const LessonEditorMain = ({ lessons }) => {
  const courseId = useCourseId()
  console.log(lessons)
  return <>
    {lessons.map((lesson, i) => {
      return <div 
        className="d-flex justify-content-between"
        key={`lesson${i}`}>
          <Link 
            to={`/${courseId}/lesson/${i+1}`}
          >
            {i+1}. {lesson.title}
          </Link>
          <div>
            <button 
              onClick={()=>setLessonVisibility(lessons, i, courseId, !lesson.isVisible)}
              className="btn btn-light btn-sm">{lesson.isVisible ? "Unpublish" : "Publish"}</button>
            <button 
              onClick={()=>editLesson(lessons, i, courseId)}
              className="btn btn-light btn-sm"><FaEdit /></button>
            <button 
              onClick={()=>deleteLesson(lessons, i, courseId)}
              className="btn btn-light btn-sm"><FaTrash /></button>
          </div>
        </div>
    })}

  <button className="btn btn-primary btn-sm" onClick={() => addLesson(lessons, courseId)}><FaPlus /> New Lesson</button>
  </>
}

async function addLesson(
  lessons,
  courseId
) {
  // get the name via a swal
  const { value: lessonName } = await Swal.fire({
    title: "New Lesson!",
    input: "text",
    inputLabel: "What is your lesson name",
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return "You can't have an empty section name!";
      }
    }
  });
  if (!lessonName) return;

  lessons.push({
    title: lessonName,
    isVisible:false
  });

  const db = getFirestore();
  const assnRef = doc(db, `lessons/${courseId}`);
  await setDoc(assnRef, { lessonsList:lessons }, { merge: true });

}

async function editLesson(
  lessons,
  lessonIndex,
  courseId
) {
  console.log(lessons, lessonIndex,lessons[lessonIndex])
  const { value: newTitle } = await Swal.fire({
    title: 'Rename Lesson',
    input: 'text',
    inputValue: lessons[lessonIndex],
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return 'You need to write something!'
      }
    }
  })
  lessons[lessonIndex].title = newTitle
  const db = getFirestore();
  const assnRef = doc(db, `lessons/${courseId}`);
  await setDoc(assnRef, { lessonsList:lessons }, { merge: true });
}

async function deleteLesson(
  lessons,
  lessonIndex,
  courseId
) {
  await Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this section!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it"
  }).then(async (result) => {
    if (result.isConfirmed) {
      lessons.splice(lessonIndex, 1);
      const db = getFirestore();
      const assnRef = doc(db, `lessons/${courseId}`);
      await setDoc(assnRef, { lessonsList: lessons }, { merge: true });
      Swal.fire("Deleted!", "Your lesson has been deleted.", "success");
    }
  })

}

async function deleteAssn(
  assns,
  courseId,
  assnIndex,
  deleteAssnId,
  callback
) {

  const oldList = assns[assnIndex].assnList
  const newList = []
  for (const assnId of oldList) {
    if (assnId != deleteAssnId) {
      newList.push(assnId)
    }
  }
  assns[assnIndex].assnList = newList

  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns: assns }, { merge: true });
  callback(assns)
}



async function setLessonVisibility(
  lessons,
  assnIndex,
  courseId,
  newVisibility
) {
  lessons[assnIndex].isVisible = newVisibility
  const db = getFirestore();
  const assnRef = doc(db, `lessons/${courseId}`);
  await setDoc(assnRef, { lessonsList: lessons}, { merge: true });
}
