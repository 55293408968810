import { collection, doc, getDoc, getFirestore, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";

/**
 * Assignments are reactive to the firebase, so if you change
 * the value there, everyones assignment list will update!
 */

// lessons documentation is here:
// https://docs.google.com/document/d/14tDLbvJIJL6ftA9_xBqwawas_1klCLhrPVY_6WwyX5c/edit

const defaultData = {
  lessons:[],
  lessonsLoading: true,
  lessonsError: null
};

export const LessonsContext = React.createContext(defaultData);
export const LessonsProvider = ({ courseId, children }) => {

  const db = getFirestore()
  const docRef = doc(db, `lessons/${courseId}`);  
  const [lessons, lessonsLoading, lessonsError] = useDocumentData(docRef)

  console.log(lessons, lessonsLoading, lessonsError)

  let lessonsSafe = lessons?.lessonsList || []

  return (
    <LessonsContext.Provider
      value={{
        lessons:lessonsSafe,
        lessonsLoading,
        lessonsError
      }}
    >
      {children}
    </LessonsContext.Provider>
  );
};



// Map subLessonIds (docId) to lesson number
const mapDocIdToLessonNum = (lessonsList) => {
  let tempMap = {}

  for (let i = 0; i < lessonsList.length; i++) {
    let lessonItems = lessonsList[i]?.items;
    if (lessonItems) {
      for (let j = 0; j < lessonItems.length; j++) {

        let docId = lessonItems[j]?.docId;
        let lessonNum = lessonsList[i]?.lessonNum;
        if (docId && lessonNum) {
          tempMap[docId] = lessonNum
        }
      }
    }
  }

  return tempMap;
}


// Map subLessonIds (docId) to lesson type 
const mapDocIdToType = (lessonsList) => {
  let tempMap = {};

  for (let i = 0; i < lessonsList.length; i++) {
    let lessonItems = lessonsList[i]?.items;
    if (lessonItems) {
      for (let j = 0; j < lessonItems.length; j++) {
        let docId = lessonItems[j]?.docId;
        let type = lessonItems[j]?.type;
        if (docId && type) {
          tempMap[docId] = type;
        }
      }
    }

  }

  return tempMap;
} 
