import React from 'react';
import { Link } from "react-scroll";

import {Header, Intro, Mission, Projects, Courses, Impact, News, Contact, Footer} from './components';
import './App.css';

export const Landing = () => {

  return (
    <div className='App'>
      <Header/>
      <section id="csb-about">{<Intro />}</section>
      <section id="csb-courses">{<Courses />}</section>
      <section id="csb-projects">{<Projects />}</section>
      <section id="csb-news">{<News />}</section>
      <Footer/>
    </div>
  )

}



