import { HandoutPage } from "course/handout/HandoutPage"


export const Handout = () => {
  return <>
    <div className="container-lg">
      <div className="mt-3">
        <HandoutPage />
      </div>
    </div>
  </>
}