
export const courseInfo = {
    title: 'CS106A',
    courseCode: 'CS106A',
    quarterFullName: 'Spring 2023',
    lectureTime: '11:30am - 12:30pm',
    midtermDate: 'May 9, 7pm',
    finalDate: 'June 9, 8:30am', //8:30a - 11:30a
    staffEmail: 'cs106a-staff@lists.stanford.edu',
    lastLectureDate: 'June 7',
    location: 'Hewlett 200',
    locationMap: 'https://goo.gl/maps/2Zj5ZfLWynW5SKR7A',
    instructors: [
        {
            name: "Mehran Sahami",
            img: `${process.env.PUBLIC_URL}/people/mehran.jpeg`,
            email: "sahami@cs",
            officeHours: "Wed 3-5pm PT",
            office: "Gates 274",

            website: "http://robotics.stanford.edu/~sahami/bio.html"
        },
        {
            name: "Chris Piech",
            img: `${process.env.PUBLIC_URL}/people/chris.jpg`,
            email: "piech@cs",
            officeHours: "Fri 1:30-3pm PT",
            office: "Durand 305",
            website: "http://web.stanford.edu/~cpiech/"
        },
    ],
    headTA: {
        name: "Elyse Cornwall",
        img: `${process.env.PUBLIC_URL}/people/elyse.jpeg`,
        email: "cornwall@",
        officeHours: "Tue/Thu 10-11:30am PT (SCPD students can email to schedule Zoom OH)",
        office: "Durand 303",
        website: 'https://www.linkedin.com/in/elysecornwall/'
    },
    lectureSchedule: {
        // note that months are zero offset (so 1 is Feb)
        // year month day
        // make them all 11:30a pacific time
        "1": new Date(2023, 3, 3, 11, 30, 0, 0),
        "2": new Date(2023, 3, 5, 11, 30, 0, 0),
        "3": new Date(2023, 3, 7, 11, 30, 0, 0),

        "4": new Date(2023, 3, 10, 11, 30, 0, 0),
        "5": new Date(2023, 3, 12, 11, 30, 0, 0),
        "6": new Date(2023, 3, 14, 11, 30, 0, 0),

        "7": new Date(2023, 3, 17, 11, 30, 0, 0),
        "8": new Date(2023, 3, 19, 11, 30, 0, 0),
        "9": new Date(2023, 3, 21, 11, 30, 0, 0),

        "10": new Date(2023, 3, 24, 11, 30, 0, 0),
        "11": new Date(2023, 3, 26, 11, 30, 0, 0),
        "12": new Date(2023, 3, 28, 11, 30, 0, 0),

        "13": new Date(2023, 4, 1, 11, 30, 0, 0),
        "14": new Date(2023, 4, 3, 11, 30, 0, 0),
        "15": new Date(2023, 4, 5, 11, 30, 0, 0),

        "16": new Date(2023, 4, 8, 11, 30, 0, 0),
        "17": new Date(2023, 4, 10, 11, 30, 0, 0),

        "18": new Date(2023, 4, 15, 11, 30, 0, 0),
        "19": new Date(2023, 4, 17, 11, 30, 0, 0),
        "20": new Date(2023, 4, 19, 11, 30, 0, 0),

        "21": new Date(2023, 4, 22, 11, 30, 0, 0),
        "22": new Date(2023, 4, 24, 11, 30, 0, 0),
        "23": new Date(2023, 4, 26, 11, 30, 0, 0),

        "24": new Date(2023, 4, 31, 11, 30, 0, 0),
        "25": new Date(2023, 5, 2, 11, 30, 0, 0),

        "26": new Date(2023, 5, 5, 11, 30, 0, 0),
        "27": new Date(2023, 5, 7, 11, 30, 0, 0),
    }
}
