import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaBookOpen, FaChalkboard, FaCog, FaComment, FaLightbulb, FaPython, FaSeedling, FaSlideshare } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import { courseInfo } from "cs106a/courseInfo";
import { loginWithRedirect } from "cs106a/authentication/Login";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { ProfileContext, Role } from "contexts/ProfileContext";
import { isMinimumRole } from "contexts/ProfileUtil";
import { LessonsContext } from "../contexts/LessonsContextCSBridge";
import { HandoutsContext } from "../contexts/HandoutsContext";
import Swal from "sweetalert2";
import { doc, getFirestore, setDoc } from "firebase/firestore";

export const CSBridgeNavbar = () => {
  const courseId = useCourseId()
  return (
    <Navbar bg="dark" variant="dark" expand="md" sticky="top">
      <div className="container-lg">
        <LinkContainer className="text-light" to={`/${courseId}`}>
          <Navbar.Brand>CSBridge</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <LeftNav />
          <RightNav />
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

const LeftNav = () => {
  const navigate = useNavigate();
  const courseId = useCourseId();
  return (
    <Nav className="me-auto">
      <CourseDropdown />
      <LessonsDropdown />
      <Nav.Item>
        <button
          onClick={() => navigate(`/${courseId}/forum`)}
          className="btn btn-sm btn-dark mr-0 text-muted"
        >
          <FaComment className="mainNavButton" />
        </button>
      </Nav.Item>

      <Nav.Item>
        <button
          onClick={() => navigate(`/${courseId}/code`)}
          className="btn btn-sm btn-dark text-muted"
        >
          <FaPython className="mainNavButton" />
        </button>
      </Nav.Item>
    </Nav>
  );
};

const AdminNav = (props) => {
  const { userData } = useContext(ProfileContext);
  const courseRole = userData?.courseRole;
  const courseId = useCourseId();
  if (!isMinimumRole(courseRole, Role.ADMIN)) {
    return null;
  }
  const icon = <FaCog className="mainNavButton" />;
  return (
    <NavDropdown className="course-profile-dropdown" title={icon}>
      <LinkContainer to={`/${courseId}/assneditor`}>
        <NavDropdown.Item>Assn Editor</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/lessoneditor`}>
        <NavDropdown.Item>Lesson Editor</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/handouteditor`}>
        <NavDropdown.Item>Handout Editor</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/docseditor`}>
        <NavDropdown.Item>IDE Docs Editor</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/setpermissions`}>
        <NavDropdown.Item>Set Permissions</NavDropdown.Item>
      </LinkContainer>


    </NavDropdown>
  );
};

const LessonsDropdown = () => {
  const courseId = useCourseId()
  const { lessons } = useContext(LessonsContext)
  return (
    <NavDropdown title={<FaSeedling className="mainNavButton" />} id="course-dropdown">
      {lessons.map((lesson, index) => {
        const userIndex = index + 1
        if (!lesson.isVisible) {
          return <></>
        }
        return <LinkContainer key={index} to={`/${courseId}/lesson/${userIndex}`}>
          <NavDropdown.Item>{userIndex}. {lesson.title}</NavDropdown.Item>
        </LinkContainer>
      })}


    </NavDropdown>
  );
};

const CourseDropdown = () => {
  const courseId = useCourseId()
  const { handouts } = useContext(HandoutsContext)
  return (
    <NavDropdown title={<FaBookOpen className="mainNavButton" />} id="course-dropdown">
      <NavDropdown.Item target="_blank" href="https://compedu.stanford.edu/karel-reader/docs/python/en/intro.html">
        Karel Textbook
      </NavDropdown.Item>

      <LinkContainer to={`/${courseId}/textbook`}>
        <NavDropdown.Item>Python Textbook</NavDropdown.Item>
      </LinkContainer>
      <NavDropdown.Divider />
      {handouts.map((handout, index) => {
        return <LinkContainer key={handout.uid} to={`/${courseId}/handout/${handout.uid}`}>
          <NavDropdown.Item>{handout.title}</NavDropdown.Item>
        </LinkContainer>
      })}
    </NavDropdown>
  );
};

const RightNav = () => {
  const courseId = useCourseId()
  const navigate = useNavigate();
  return (
    <Nav className="ml-auto">
      <AdminNav />

      {/* <Nav.Link href="#home"><FaThLarge/></Nav.Link> */}
      {/* Profile dropdown with logout */}
      <SignInSignOut />
    </Nav>
  );
};

const SignInSignOut = () => {
  const auth = getAuth(getApp());
  const { userData, setUserData } = useContext(ProfileContext)
  return (
    <>
      <NavDropdown title={getDisplayName(userData)} id="profile-dropdown">
        <NavDropdown.Item onClick={() => promptForName(userData, setUserData)}>
          Edit Name
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => signOut(auth)}>
          Sign Out
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

const getDisplayName = (userData) => {
  if (!userData) return "";
  if (!userData.displayName) return "Anonymous"
  return userData.displayName.split(" ")[0];
}


async function promptForName(userData, setUserData) {
  Swal.fire({
    title: "Welcome to CSBridge!",
    input: "text",
    inputLabel: "What is your name?",
    showCancelButton: false,
    inputValidator: (value) => {
      if (!value) {
        return "You can't have an empty name!";
      }
    }
  }).then((result) => {
    if (result.isConfirmed) {
      const newDisplayName = result.value
      setUserData({ displayName: newDisplayName })
      // const db = getFirestore();
      // const userRef = doc(db, `users/${userData.uid}`);
      // setDoc(userRef, { displayName: newDisplayName }, { merge: true });
      // const newUserData = {...userData}
      // newUserData.displayName = newDisplayName
      // setUserData(newUserData)
    }
  })

}