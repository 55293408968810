import React, { useContext, useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FaEdit, FaEye, FaQuoteLeft, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useCourseId } from 'hooks/router/useUrlParams';

export const SortableHandoutTable = ({ handouts, onOrderChange, deleteHandout,renameHandout }) => {
  const courseId = useCourseId()
  const [items, setItems] = useState(handouts);

  useEffect(() => {
    setItems(handouts)
  }, [handouts])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
      >
        {items.map((item, index) => (
          <SortableItem
            key={item.uid}
            id={item.uid}
            item = {item}
            courseId={courseId}
            deleteAssn={() => deleteHandout(index)}
            renameHandout={() => renameHandout(index)}
          />))}
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event) {
    
    const { active, over } = event;

    console.log(active)
    console.log(over)

    const getIndex = (items, id) => {
      for(let i=0; i<items.length; i++) {
        if(items[i].uid === id) return i
      }
      return -1
    }

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = getIndex(items, active.id);
        const newIndex = getIndex(items, over.id);

        const newList = arrayMove(items, oldIndex, newIndex);
        console.log(oldIndex, newIndex)
        onOrderChange(newList)
        return newList
      });
    }
  }
}

export function SortableItem(props) {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handoutData = props.item
  const handoutId = handoutData.uid
  const handoutName = handoutData.title
  
  // let handoutName = 'Untitled'
  // if(handoutMetaData?.[handoutId]) {
  //   handoutName = handoutMetaData[handoutId].title
  // }
  
  const editLink = `/${props.courseId}/handout/${handoutId}`

  return (
    <>
      <div className='d-flex justify-content-between w-100'>
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>

          {handoutName}
        </div>
        
        <span>
          <Button
            onClick={() => props.deleteAssn()}
            className='btn btn-light btn-sm mr-2'
          >
            <FaTrash className="" />
          </Button>
          <Button
            onClick={() => props.renameHandout()}
            className='btn btn-light btn-sm mr-2'
          >
            <FaQuoteLeft className="" />
          </Button>
          <Link to={editLink}><FaEdit /></Link>
        </span>


      </div>
    </>
  );
}