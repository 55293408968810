import { Handout } from "cs106a/components/Handout"
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faCheckCircle, faCode, faFilePdf, faVideo } from "@fortawesome/free-solid-svg-icons";
import { ProfileContext, Role } from "contexts/ProfileContext";
import { useContext, useEffect, useState } from "react";
import { uploadPDF } from "utils/uploadPDF";
import { ProgressBar } from "react-bootstrap";
import { uuidv4 } from "lib0/random";
import firebase from "firebase/compat/app";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { useCourseId } from "hooks/router/useUrlParams";
import { TipTap } from "components/richTextEditor/TipTap/TipTap";
import { DiscussionButtonBar } from "components/richTextEditor/TipTap/buttonbars/DiscussionButtonBar";
import { FaEdit, FaUpload } from "react-icons/fa";
import Swal from "sweetalert2";
import Loading from "react-loading";
import { isMinimumRole } from "contexts/ProfileUtil";

export const Lesson = () => {
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    const params = useParams();
    const lectureNum = params.lessonIndex
    const courseId = useCourseId()
    // document.title = "CSBridge | Lesson " + lectureNum

    const path = `lectures/${courseId}/${lectureNum}/doc`
    console.log(path)
    const db = firebase.firestore()
    const [serverData, serverIsLoading, serverError] = useDocumentData(doc(db, path))


    if(serverIsLoading){
      return <></>
    }

    if(serverError){
      return <>Please refresh</>
    }


    return <LectureWithData key={lectureNum} data={serverData} />

}

const LectureWithData = ({data}) => {
  console.log(data)
  const {userData} = useContext(ProfileContext)

  const isAdmin = isMinimumRole(userData?.courseRole, Role.TA)
 
  
  const title = data?.title || ""
  const lectureNum = useParams().lessonIndex
    return (
      <div>
        <h1>
          Lesson {lectureNum}: {title} {isAdmin && <SetTitleBtn />}
        </h1>
        <hr />
        <LearningGoals />
        <div style={{height:15}}/>
  
        {renderLectureSlides(data)}
        

        <div style={{height:15}}/>
        <RelatedReading/>
      </div>
    );
  };
  
  const renderLectureSlides = (data) => {
    const {userData} = useContext(ProfileContext)
    const isAdmin = isMinimumRole(userData?.courseRole, Role.TA)





    return <>
      <h3>Lecture Slides  {isAdmin && <UploadSlides />}</h3>
      {renderLinkList([{
        title: "Lecture Slides",
        url: data?.slidesUrl
      }], faFilePdf)}
     
    </>
    
  };

  const UploadSlides = () => {
    const [progress, setProgress] = useState(0)
    const lectureNum = useParams().lessonIndex
    const courseId = useCourseId()

        
    const uploadSlides = () => {
      const fileName = `cs106a-lecture-${lectureNum}.pdf`
      const storagePath = `lectures/${courseId}/${lectureNum}/${fileName}`
      
      const onComplete = (url) => {
        setProgress(0)
        
        // save it to the database
        const db = firebase.firestore()
        const path = `lectures/${courseId}/${lectureNum}/doc`
        db.doc(path).set({
          slidesUrl: url,
        }, {merge: true})        
      }
      const onUploadProgress = (progress) => {
        setProgress(progress)
      }
      const onErrorState = (error) => {
        setProgress(0)
        Swal.fire({
          title: "Error",
          text: "Did not upload",
          icon: "error",
        });
      }
      uploadPDF(storagePath, onComplete, onUploadProgress, onErrorState)
    }

    if(progress > 0) {
      return <ProgressBar now={progress} />
    }

    return <>
      <button onClick = {uploadSlides} className="btn btn-light btn-sm"><FaUpload/></button>
    </>
  }
  
  const renderWorkedExamples = (examples) => {
    return renderLinkList(examples, faCheckCircle);
  };
  
  const renderAssn = (assnData) => {
    if (assnData.length == 0) {
      return <span />;
    }
    return (
      <div>
        <h3>Assignment Problems</h3>
  
        <p>
          After you watch this lesson, solve these assignment challenges:
          <br />
          {renderLinkList(assnData, faCode)}
        </p>
      </div>
    );
  };
  
  const renderReading = (readingData) => {
    return renderLinkList(readingData, faBookOpen);
  };
  
  const renderLinkList = (listData, iconType) => {
    return (
      <div className="d-flex flex-wrap">
        {listData.map((element, index) => (
          <div className="flex-1 mr-3" key={index}>
            <div className="d-flex flex-column align-items-center">
              <a target="_blank" href={element["url"]}>
                <FontAwesomeIcon
                  icon={
                    element["title"] == "Lecture Slides" ? faFilePdf : iconType
                  }
                  size="3x"
                />
              </a>
              <a
                target="_blank"
                href={element["url"]}
                style={{ textAlign: "center" }}
              >
                {element["title"]}
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const LearningGoals = () => {
    const {userData} = useContext(ProfileContext)
    const isAdmin = isMinimumRole(userData?.courseRole, Role.TA)
    const [editing, setEditing] = useState(false)
    const lectureNum = useParams().lessonIndex
    const courseId = useCourseId()
    const path = `lectures/${courseId}/${lectureNum}/learningGoals`
    console.log(path)
    return <>
    <h3>Learning Goals {isAdmin && <button className="btn btn-light btn-sm" onClick={() => setEditing(!editing)}>{editing ? "Done" : <FaEdit/>}</button>}</h3>
    <TipTap 
      editable={editing}
      firebaseDocPath={path}
      onServerWrite= {() => {}}
      collaborative = {true}
      handleUpdate = {() => {}}
      buttonBar = {DiscussionButtonBar}
      showCursors={true}
    />
    
    
    </>
  }

  const RelatedReading = () => {
    const {userData} = useContext(ProfileContext)
    const isAdmin = isMinimumRole(userData?.courseRole, Role.TA)
    const [editing, setEditing] = useState(false)
    const lectureNum = useParams().lessonIndex
    const courseId = useCourseId()
    const path = `lectures/${courseId}/${lectureNum}/relatedReading`
    return <>
    <h3>Notes {isAdmin && <button className="btn btn-light btn-sm" onClick={() => setEditing(!editing)}>{editing ? "Done" : <FaEdit/>}</button>}</h3>
    <TipTap 
      editable={editing}
      firebaseDocPath={path}
      onServerWrite= {() => {}}
      collaborative = {true}
      handleUpdate = {() => {}}
      buttonBar = {DiscussionButtonBar}
      showCursors={editing}
    />
    
    
    </>
  }
  
  const renderLectureVideos = (videosData) => {
    if (!videosData || videosData.length == 0) {
      return <>
      <h3>Recorded Videos</h3>
      <p>Videos will be uploaded within 24 hours of the class:</p>
      {renderLinkList([{
        title: "Lecture Video",
        url: "https://canvas.stanford.edu/courses/170417/external_tools/3367"
      }], faVideo)}
      
      </>
    }
    return (<>
        <h3>Recorded Videos</h3>
      <div className="row">
        {videosData.map((video, index) => renderLectureVideo(video, index))}
      </div>
      </>);
  };
  
  const renderLectureVideo = (videoData, index) => {
    return <></>
    // return <LectureVideo videoData={videoData} index={index} />;
  };

  const SetTitleBtn = () => {
    const courseId = useCourseId()
    const lectureNum = useParams().lessonIndex
    const editTitle = () => {
      Swal.fire({
        title: "Set Title",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Set",
        showLoaderOnConfirm: true,
        preConfirm: (title) => {
          const db = firebase.firestore()
          const path = `lectures/${courseId}/${lectureNum}/doc`
          db.doc(path).set({
            title: title,
          }, {merge: true})
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
         console.log('successfully changed title')
        }
      });
    }
    const {userData} = useContext(ProfileContext)
    const isAdmin = isMinimumRole(userData?.courseRole, Role.TA)
    return isAdmin ? <button className="btn btn-light" onClick={() => editTitle()}><FaEdit/></button> : <></>
  }
  