import React, { ReactNode, useContext, useEffect, useState } from "react";
import { deleteDoc, getDoc, getDocFromServer, updateDoc } from "firebase/firestore";
import { RepliesLikeData, Reply } from "../../firebase/ForumDataModels";
import { ViewFlaggedPostButton } from "../FlagButton/ViewFlaggedPost";

import "./PostReply.css";
import { Button, Skeleton, Alert } from "antd";

import { DateTime } from "luxon";

import { NewReplyEditor } from "../NewReplyEditor/NewReplyEditor";
import { PostReplies } from "../PostReplies/PostReplies";
import { TipTap } from "../../../../components/richTextEditor/TipTap/TipTap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { LikeButton } from "../LikeButton/LikeButton";
import { UserChip } from "../UserChip/UserChip";
import { ForumContext } from "course/forum/ForumContext";
import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { ProfileContext } from "contexts/ProfileContext";
import Gate from "contexts/Gate";
import { DiscussionButtonBar } from "../../../../components/richTextEditor/TipTap/buttonbars/DiscussionButtonBar";
import { FaCommentDots, FaEdit, FaFlag, FaTrash } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCourseId } from "hooks/router/useUrlParams";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { LikeButtonFast } from "../LikeButton/LikeButtonFast";
import { FlagButton } from "../FlagButton/FlagPostButton";
import { popLoadingAlert, confirmDelete } from "../forumGeneral";


export function PostReply(props: {
  replyId: string;
  postId: string;
  level: number;
}) {
  const courseId = useCourseId();
  const { replyId, postId, level } = props;
  const dataFetcher = useForumDataFetcher();
  const { forumId } = useContext(ForumContext);
  const { userData } = useContext(ProfileContext);
  const [viewFlaggedPost, setViewFlaggedPost] = useState(false);

  const auth = getAuth(getApp());
  const [user] = useAuthState(auth);

  const queryClient = useQueryClient();

  const replyDataQuery = useQuery(["reply", replyId], () =>
    getDocFromServer(dataFetcher.get_reply_doc_ref(replyId))
  );

  const [showReplyBox, setShowReplyBox] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [repliesOpen, setRepliesOpen] = useState(level < 3);
  const [isPreviewing, setIsPreviewing] = useState(false);

  const resetChildReply = () => {
    setShowReplyBox(false);
  };

  const flagReply = async () => {
    const resolveLoading = popLoadingAlert()
    const newFlagData: Partial<Reply> = {
      isFlagged: true,
    };
    await updateDoc(dataFetcher.get_reply_doc_ref(replyId), newFlagData);
    resolveLoading();
    replyDataQuery.refetch();

  }

  const functions = getFunctions();
  const toggleLike = httpsCallable(functions, "likeEvent");
  const updateReplyTipTap = httpsCallable(functions, "updateReplyTipTap");

  const likeHandler = useMutation(
    async (data: { newLike: boolean }) => {
      const { newLike } = data;
      await toggleLike({
        courseId,
        forumId,
        targetType: "Reply",
        targetId: replyId,
        isLike: newLike,
      });

      return getDoc(dataFetcher.get_reply_doc_ref(replyId));
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(["reply", replyId], result);
      },
    }
  );

  const deleteMutation = useMutation(async () => {
    const confirm = await confirmDelete();
    if (!confirm) {
      return;
    }
    await deleteDoc(dataFetcher.get_reply_doc_ref(replyId));
  });

  if (replyDataQuery.isLoading) {
    return <Skeleton />;
  }

  const reply = replyDataQuery.data.data();
  const { author, authorUid, time, likedBy, isFlagged } = reply;

  if(isFlagged && !viewFlaggedPost) {
    return <ModeratedReply dataFetcher={dataFetcher} replyId={replyId} isMod={Gate.hasStaffRole(userData)} dt={DateTime.fromJSDate(time)}  toggleViewFlaggedPost={() => setViewFlaggedPost(!viewFlaggedPost)} isViewing={viewFlaggedPost} unFlagCallback={()=>replyDataQuery.refetch()}/>
  }

  const publishReply = async () => {
    const resolveLoading = popLoadingAlert("Saving reply...");
    setIsPreviewing(true);
    await updateReplyTipTap({
      courseId,
      forumId,
      replyId
    })
    resolveLoading();
    setIsEditing(false);
    setIsPreviewing(false);
  }

  const actions: ReactNode[] = [];

  const canEdit = Gate.hasStaffRole(userData) || authorUid === user.uid;

  if(Gate.hasStaffRole(userData) && isFlagged) {  
    actions.push(
      <ViewFlaggedPostButton onClick={() => setViewFlaggedPost(!viewFlaggedPost)} isViewing={viewFlaggedPost} iconSize={15} key="viewFlaggedPost" />
    )

  }

  if (canEdit) {
    actions.push(<EditButton onClick={() => setIsEditing(true)} key="edit" />);
    actions.push(
      <DeleteButton onClick={() => deleteMutation.mutate()} key="delete" />
    );
  }

  if(Gate.hasStaffRole(userData)) {
    actions.push(
      <FlagButton
        isFlagged={reply.isFlagged}
        onClick={flagReply}
        iconSize={15}
        key="flag"
      />
    );
  }

  if (!showReplyBox) {
    actions.push(
      <ReplyButton onClick={() => setShowReplyBox(true)} key="reply" />
    );
  }

  actions.push(
    <LikeButtonFast
      likedBy={likedBy}
      likeHandler={async (newLike) => likeHandler.mutate({ newLike })}
      iconSize={15}
      key="like"
      showNumber={true}
    />
  );

  const dt = DateTime.fromJSDate(time);


  return (
    <div className="comment">
      {!isEditing && (
        <div className="post-metadata">
          <UserChip showAvatar={false} uid={authorUid} name={author} />
          <span className="reply-date">
            {dt.toLocaleString({
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
            })}
          </span>
        </div>
      )}

      <div className="tipTapContainer">
        <TipTap
          editable={isEditing && !isPreviewing}
          firebaseDocPath={isEditing ? dataFetcher.get_draft_tiptap_doc_path(replyId) : dataFetcher.get_tiptap_doc_path(replyId)}
          onServerWrite={null}
          buttonBar={DiscussionButtonBar}
          showLoadingSkeleton={false}
        />
      </div>
      {isEditing && (
        <div className="saveReplyContainer">
          <Button
            type="primary"
            onClick={publishReply}
            size="small"
            className="saveEditedReply"
          >
            Save
          </Button>
        </div>
      )}
      {!isEditing && <div className="reply-actions">{actions}</div>}
      {showReplyBox && !isEditing && (
        <NewReplyEditor
          parent={replyId}
          onSubmit={resetChildReply}
          postId={postId}
          setIsReplying={(r) => setShowReplyBox(r)}
        />
      )}
      <PostReplies
        parent={replyId}
        level={level + 1}
        condensed={!repliesOpen}
        postId={postId}
      />
    </div>
  );
}

const EditButton = (props: { onClick: () => void }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 400, hide: 0 }}
      overlay={(props) => (
        <Tooltip id="resolve-tooltip" {...props}>
          Edit
        </Tooltip>
      )}
    >
      <span>
        <FaEdit onClick={props.onClick} className="action-button" />{" "}
      </span>
    </OverlayTrigger>
  );
};

const DeleteButton = (props: { onClick: () => void }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 400, hide: 0 }}
      overlay={(props) => (
        <Tooltip id="resolve-tooltip" {...props}>
          Delete
        </Tooltip>
      )}
    >
      <span>
        <FaTrash onClick={props.onClick} className="action-button" />{" "}
      </span>
    </OverlayTrigger>
  );
};

const ReplyButton = (props: { onClick: () => void }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 400, hide: 0 }}
      overlay={(props) => (
        <Tooltip id="resolve-tooltip" {...props}>
          Reply
        </Tooltip>
      )}
    >
      <span>
        <FaCommentDots onClick={props.onClick} className="action-button" />{" "}
      </span>
    </OverlayTrigger>
  );
};



export const ModeratedReply = (props: {dataFetcher: any, replyId: string, isMod: boolean, dt: any, toggleViewFlaggedPost: () => any, isViewing:boolean, unFlagCallback: () => any}) => {
  const { dataFetcher, replyId, isMod, dt, toggleViewFlaggedPost, isViewing, unFlagCallback } = props;


  const unFlagPost = async () => {
    const resolveLoading = popLoadingAlert()

    const newFlagData: Partial<Reply> = {
      isFlagged: false,
    };

    await updateDoc(dataFetcher.get_reply_doc_ref(replyId), newFlagData);
    resolveLoading();
    unFlagCallback();
  }


  return (
      
      <div className="comment">
        <div className="post-metadata">
          [REMOVED]
          <span className="reply-date">
            {dt.toLocaleString({
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
            })}
          </span>
        </div>
        <ModeratedReplySplash isMod={isMod} unFlagPost={unFlagPost} toggleViewFlaggedPost={toggleViewFlaggedPost} isViewing={isViewing} />

      </div>
  )
}



const ModeratedReplySplash = ({isMod, unFlagPost, toggleViewFlaggedPost, isViewing}) => {


  return (
    <div>
      <div className="editorContainer">
        <div>
          <Alert
            description={
              <span>
                This reply has been removed by a moderator.
              </span>
            }
            type="info"
            icon={<FaFlag />}
            showIcon
            className="p-2 bg-light"
          />
        <div className="reply-actions">
        { isMod && <ViewFlaggedPostButton onClick={toggleViewFlaggedPost} isViewing={isViewing}  iconSize={15}/>}
          { isMod && <FlagButton isFlagged={true} onClick={unFlagPost} iconSize={15} />}
        </div>
        </div>
      </div>
    </div>
  );
};
