function Karel() {
}

Karel.instructions = {
   move: 1, turn_left: 1, put_beeper: 1, pick_beeper: 1, paint_corner: 2,
   // For CS Bridge - tell the linter that Spanish language instructions are valid.
   moverse: 1, girar_izquierda: 1, poner_cono: 1, recoger_cono: 1, pintar_esquina: 2,
};

Karel.predicates = {
   front_is_clear: 1, front_is_blocked: 1,
   left_is_clear: 1, left_is_blocked: 1,
   right_is_clear: 1, right_is_blocked: 1,
   beepers_present: 1, no_beepers_present: 1,
   facing_north: 1, not_facing_north: 1,
   facing_east: 1, not_facing_east: 1,
   facing_south: 1, not_facing_south: 1,
   facing_west: 1, not_facing_west: 1,
   beepers_in_bag: 1,
   no_beepers_in_bag: 1,
   corner_color_is: 2, random: 2,
   // For CS Bridge - tell the linter that Spanish language predicates are valid.
   frente_despejado: 1, frente_bloqueado: 1,
   izquierda_despejada: 1, izquierda_bloqueada: 1,
   derecha_despejada: 1, derecha_bloqueada: 1,
   conos_presentes: 1, conos_ausentes: 1,
   bolsa_con_conos: 1, bolsa_sin_conos: 1,
   rumbo_norte: 1, sin_rumbo_norte: 1,
   rumbo_este: 1, sin_rumbo_este: 1,
   rumbo_sur: 1, sin_rumbo_sur: 1,
   rumbo_oeste: 1, sin_rumbo_oeste: 1,
   esquina_color_es: 2, aleatorio: 2,
};

export default Karel
