/**
 * Only course admins can edit this RTE (with an edit button below the RTE)
 * Users see just the HTML rendering
 */

import { TipTap } from "components/richTextEditor/TipTap/TipTap"
import { HandoutButtonBar } from "components/richTextEditor/TipTap/buttonbars/HandoutButtonBar"
import { ProfileContext, Role } from "contexts/ProfileContext"
import { isMinimumRole } from "contexts/ProfileUtil"
import { useContext, useState } from "react"
import { FaEdit } from "react-icons/fa"
import styled from "styled-components"

// must be in a ProfileContext

export const AdminRichTextEditor = ({ firebaseDocPath, minRole = Role.TA, inline= false }) => {
  const {userData} = useContext(ProfileContext)
  
  const courseRole = userData.courseRole

  const hasPermissions = isMinimumRole(courseRole, minRole)

  const [isEditing, setIsEditing] = useState(false)
  return <Outer className="handout-tiptap">
  <TipTap
    firebaseDocPath={firebaseDocPath}
    editable={isEditing}
    collaborative={isEditing}
    buttonBar={HandoutButtonBar}
    showLoadingSkeleton={true}
  />
    <EditButton
      hasPermissions={hasPermissions}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      inline={inline}
    />
  </Outer>
}

const EditButton = ({hasPermissions, isEditing, setIsEditing, inline}) => {

  if(!hasPermissions) return <></>

  const style = inline ? {
    position: "absolute",
    right: "0",
    top: "0",
  } : {}

  const className = inline ? "btn btn-light btn-sm" : "btn btn-light"

  return <button style={style} className={className} onClick={() => setIsEditing(!isEditing)}>
    {isEditing ? "Done" : <FaEdit/>}
  </button>


  {/* // {hasPermissions && <button className="btn btn-light" onClick={() => setIsEditing(!isEditing)}>
  //     {isEditing ? "Done" : <FaEdit/>}
  //   </button>} */}
  return <></>
}

const Outer = styled.div`
  position: relative;
  width:100%
`