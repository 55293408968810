import { createNewAssignment } from "course/assnEditor/AssnEditor"
import { SortableAssnTable } from "course/assnEditor/SortableAssnTable"
import { AssnContext } from "course/contexts/AssnContext"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext, useEffect } from "react"
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa"
import { Link, useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { ExamplesContext } from "../contexts/ExamplesContext"

export const WorkedExamplesEditor = () => { 
  const { courseId } = useParams()
  const navigate = useNavigate()

  const { examples, examplesLoading } = useContext(ExamplesContext)

  

  if (examplesLoading) return <></>

 

  return <div className="container-lg mt-3">
    {/* {assignments.map(function (assn, i) {
      return <AssnSection
        assns={assignments}
        assnIndex={i}
        assn={assn}
        onOrderChange={(e) => saveAssns(assignments, courseId, i, e)}
        addNewAssn={() => addAssn(assignments, assnMetaData, courseId, i, onAssnCreated)}
        deleteAssn={(assnId) => deleteAssn(assignments, courseId, i, assnId, () => { })}
        courseId={courseId}
        key={i}
      />;
    })} */}
    <button className="btn btn-primary btn-sm" onClick={() => {}}><FaPlus /> New Example</button>
    <hr/>
    <div style={{height:50}}/>
    
    <UnlistedAssignmentsTable/>
  </div>
}

const UnlistedAssignmentsTable= ()=>{
  const courseId = useCourseId()
  const { assnMetaData } = useContext(AssnContext)

  console.log(assnMetaData)
  return <>
  <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">All Assignments and Examples</div>
  
  {
    Object.keys(assnMetaData).map((assnId)=>{
      const editUrl = `/${courseId}/assnEditor/${assnId}`
      const title = assnMetaData[assnId].title
      return <><Link to={editUrl}>{title} ({assnId})</Link><br/></>
    })
  }
  <div style={{height:50}}/>
  </>
}


async function addSection(
  assns,
  courseId,
  callback
) {
  // get the name via a swal
  const { value: sectionName } = await Swal.fire({
    title: "New Section!",
    input: "text",
    inputLabel: "What is your section name",
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return "You can't have an empty section name!";
      }
    }
  });
  if (!sectionName) return;

  assns.push({
    title: sectionName,
    assnList: []
  });

  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns: assns }, { merge: true });
  callback(assns)

}

async function deleteSection(
  assns,
  courseId,
  assnIndex
) {
  await Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this section!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it"
  }).then(async (result) => {
    if (result.isConfirmed) {
      assns.splice(assnIndex, 1);
      const db = getFirestore();
      const assnRef = doc(db, `assns/${courseId}`);
      await setDoc(assnRef, { assns: assns }, { merge: true });
      Swal.fire("Deleted!", "Your section has been deleted.", "success");
    }
  })

}

async function deleteAssn(
  assns,
  courseId,
  assnIndex,
  deleteAssnId,
  callback
) {

  const oldList = assns[assnIndex].assnList
  const newList = []
  for (const assnId of oldList) {
    if (assnId != deleteAssnId) {
      newList.push(assnId)
    }
  }
  assns[assnIndex].assnList = newList

  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns: assns }, { merge: true });
  callback(assns)
}

async function renameSection(
  assns,
  courseId,
  assnIndex
) {
  const { value: newTitle } = await Swal.fire({
    title: 'Rename Section',
    input: 'text',
    inputValue: assns[assnIndex].title,
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return 'You need to write something!'
      }
    }
  })
  assns[assnIndex].title = newTitle
  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns }, { merge: true });
}

async function setSectionVisibility(
  assns,
  courseId,
  assnIndex,
  newVisibility
) {
  assns[assnIndex].isVisible = newVisibility
  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns }, { merge: true });
}

async function addAssn(
  assns,
  assnMetaData,
  courseId,
  assnIndex,
  callback
) {

  const { value: newAssnId } = await Swal.fire({
    title: "New Assignment!",
    input: "text",
    inputLabel: "What is your assignment id",
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return "You can't have an empty assnId!";
      }
    },
  });

  if(!(newAssnId in assnMetaData)) {
    await createNewAssignment(
      courseId,
      newAssnId, () => { }
    )
  }
  

  const insert = assns
  const newList = assns[assnIndex].assnList
  newList.push(newAssnId)
  assns[assnIndex].assnList = newList

  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns: insert }, { merge: true });
  callback(assns, newAssnId)
}


async function saveAssns(
  assns,
  courseId,
  assnIndex,
  assnList,
) {

  const insert = assns
  assns[assnIndex].assnList = assnList
  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns: insert }, { merge: true });
}