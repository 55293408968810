import PyodideProvider from "components/pyodide/PyodideProvider";
import { Outlet } from "react-router";
import { ProfileContext, ProfileProvider } from "contexts/ProfileContext";
import { useContext } from "react";
import { CSBridgeNavbar } from "./Navbar";

import '../styles/csbridge.scss'
import { AssnProvider } from "course/contexts/AssnContext";
import { useCourseId } from "hooks/router/useUrlParams";

const validCourses = {
  'uniandes23': true,
  'uniandes24': true,
  'auw23': true,
}

export const CoursePage = () => {
  const courseId = useCourseId()
  if (!(courseId in validCourses)) {
    return <>Invalid course: {courseId}</>
  }
  return <CoursePageInner />
}


const CoursePageInner = () => {
  const { loading: userIsLoading, userData } = useContext(ProfileContext)
  if (userIsLoading) return <></>;
  return <>
    <CSBridgeNavbar />
    <main>
      <Outlet />
    </main>
  </>
}
export default CoursePage;
