import { deepCopy } from "@firebase/util"
import { WorldEditor } from "components/pyodide/KarelLib/WorldEditor"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useState, useEffect } from "react"
import { FaArrowDown, FaArrowUp, FaTrash, FaPlus, FaEraser } from "react-icons/fa"
import { checkIsProjectKarel } from "ide/utils/general"
import isEqual from 'lodash/isEqual';


export const EditUnitTests = ({serverAssnData, courseId, assnId}) => {
    console.log(serverAssnData)
    const serverUnitTests = serverAssnData?.unitTests?.unitTests
    const initUnitTests = serverUnitTests ? serverUnitTests : []

    const [unitTests, setUnitTests] = useState(initUnitTests)
    const [dirtyBit, setDirtyBit] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const isKarelAssn = checkIsProjectKarel({},serverAssnData);

    const saveUnitTests = () => {
        setIsSaving(true)
        saveToDatabase(courseId, assnId, unitTests, () => {
            setDirtyBit(false)
            setIsSaving(false)
        })
    }


    const deleteUnitTest = (i) => {
        setUnitTests(oldUnitTests => {

            const newUnitTest = deepCopy(oldUnitTests)

            newUnitTest.splice(i, 1);
            return newUnitTest
        })
        setDirtyBit(true)
    }

    const editUnitTest = (i, key, value) => {
        setUnitTests(oldUnitTests => {
            const newUnitTest = deepCopy(oldUnitTests)
            newUnitTest[i][key] = value
            return newUnitTest
        })
        setDirtyBit(true)

    }

    const setUnitTestIndex = (oldIndex, newIndex) => {
        setUnitTests(oldUnitTests => {
            const newUnitTest = deepCopy(oldUnitTests)
            newUnitTest.splice(newIndex, 0, newUnitTest.splice(oldIndex, 1)[0]);
            return newUnitTest
        })
        setDirtyBit(true)

    }

    const addUnitTest = () => {
      if (isKarelAssn) {
        const pre = makeDefaultWorld()
        const post = makeDefaultWorld()
        setUnitTests(oldUnitTests => {
            // since unitTest is a compound object
            // we use this way of updating state (otherwise view
            // wont react)
            const newUnitTest = deepCopy(oldUnitTests)
            newUnitTest.push({
                'pre':pre,
                'post':post,
                'name':`${pre.nRows}x${pre.nCols}`
            })
            return newUnitTest
        })
      }
      else {
        const pre = []
        const post = []
        setUnitTests(oldUnitTests => {
            // since unitTest is a compound object
            // we use this way of updating state (otherwise view
            // wont react)
            const newUnitTest = deepCopy(oldUnitTests)
            newUnitTest.push({
                'pre':pre,
                'post':post,
                'description': "",
                'name':`New Console Test`,
                'key': Date.now(),
                'dialogue': []
            })
            return newUnitTest
        })
      }

        setDirtyBit(true)
    }

    let saveButtonTxt = dirtyBit ? 'Save' : 'Saved'
    if(isSaving) {
        saveButtonTxt = isSaving ? 'Saving...' : saveButtonTxt
    }


    return <div className="">
        <div className='d-flex flex-column mt-3'>
        <hr/>
        <h3>Unit Tests</h3>

        {unitTests.map(function(unitTestData, i){
          if(! unitTests[i].key) {
            unitTests[i].key = i + Date.now()
          }
        return isKarelAssn ? <KarelUnitTest
          unitTestData={unitTestData}
          serverAssnData={serverAssnData}
          deleteUnitTest = {() => deleteUnitTest(i)}
          editUnitTest={(k,v) => editUnitTest(i, k, v)}
          setUnitTestIndex ={(newIndex) => setUnitTestIndex(i, newIndex)}
          unitTests={unitTests}
          key={i+unitTestData.name}
          index = {i}
        /> : <ConsoleUnitTest
        unitTestData={unitTestData}
        serverAssnData={serverAssnData}
        deleteUnitTest = {() => deleteUnitTest(i)}
        editUnitTest={(k,v) => editUnitTest(i, k, v)}
        setUnitTestIndex ={(newIndex) => setUnitTestIndex(i, newIndex)}
        unitTests={unitTests}
        key={unitTests[i].key}
        index = {i}
        />;
    })}

        </div>
        <button onClick={() => addUnitTest()}className="btn btn-secondary ">Add Unit Test</button>
        <button
            disabled={!dirtyBit || isSaving}
            onClick={() => saveUnitTests()}
            style={{width:100}}
            className="btn btn-primary ml-2"
        >{saveButtonTxt}</button>
    </div>
}

const KarelUnitTest = ({unitTestData, serverAssnData,unitTests,deleteUnitTest, editUnitTest,index,setUnitTestIndex}) => {
  console.log(unitTestData)

    const setPreState = (updateFn) => {
        const editedState = updateFn(unitTestData.pre)
        editUnitTest('pre', editedState)
    }

    const setPostState = (updateFn) => {
        const editedState = updateFn(unitTestData.post)
        editUnitTest('post', editedState)
    }

    const updateName = (newName) => {
        editUnitTest('name', newName)
    }

    const moveUp = () => {
        if(index > 0){
            setUnitTestIndex(index-1)
        }
    }

    const moveDown = () => {
        if(index < unitTests.length -1){
            setUnitTestIndex(index+1)
        }
    }

    return <>

    <b>Pre:</b>
    <WorldEditor
        worldState={unitTestData.pre}
        setWorldState={setPreState}
    />
    <b>Post:</b>
    <WorldEditor
        worldState={unitTestData.post}
        setWorldState={setPostState}
    />
     <b>Name:</b>
        <input type="text" className="form-control" placeholder="Enter title"
        value = {unitTestData.name}
        onChange={(e)=>{updateName(e.target.value)}}/>
    <div>
        <button onClick={moveUp}className="btn btn-light"><FaArrowUp/></button>
        <button onClick={moveDown}className="btn btn-light"><FaArrowDown/></button>
        <button onClick={deleteUnitTest}className="btn btn-light"><FaTrash/></button>
        <hr/>
    </div>
    </>
}

const makeDefaultWorld = () => {
    return {
        nRows:3,
        nCols:4,
        karelRow:0,
        karelCol:0,
        karelDir:'East'
    }
}

async function saveToDatabase(
    courseId,
    assnId,
    unitTests,
    callbackFn
  ) {
    const db = getFirestore();
    const path = `assns/${courseId}/assignments/${assnId}/docs/unitTests`
    console.log('saveUnitTests', path)
    const assnRef = doc(db, path);
    await setDoc(assnRef, {unitTests:unitTests}, { merge: true });
    callbackFn(unitTests)
  }


const ConsoleUnitTest = ({unitTestData, serverAssnData,unitTests,deleteUnitTest, editUnitTest,index,setUnitTestIndex}) => {
  //unitTestData.dialogue ??
  const [dialogueList, setDialogueList] = useState( unitTestData.dialogue ?? [])
  const [dialogueIndex, setDialogueIndex] = useState(unitTestData.dialogue ?
        unitTestData.dialogue.reduce((max, item) => {
          return Math.max(max, item.order);
        }, Number.MIN_VALUE) + 1 :
        0 )
  const [testName, setTestName] = useState(unitTestData.name ?? "")
  const [testDescription, setTestDescription] = useState(unitTestData.description ?? "");



  const editConsoleUnitTest = () => {
    const outputList = dialogueList.sort((a, b) => (a.order > b.order) ? 1 : -1).map((val) =>  val.output)
    const inputList = dialogueList.filter(data => data.type === "input").sort((a, b) => (a.order > b.order) ? 1 : -1).map((val) =>  val.input)
    editUnitTest("post", outputList)
    editUnitTest("pre", inputList)
    editUnitTest("dialogue", dialogueList)
    editUnitTest("description", testDescription)
    editUnitTest("name", testName)
  }


  const addDialogue = (isPrint) => {
    const data = {
      order: dialogueIndex,
      output:  "" ,
      input: "",
      type: isPrint ? "print" : "input"
    }

    dialogueList.push(data)
    setDialogueIndex(dialogueIndex + 1);
    editConsoleUnitTest();
  }

  const removeDialogue = (idx) => {
    dialogueList.splice(idx, 1);
    setDialogueList(dialogueList)
    editConsoleUnitTest();
  }

  const updateDialogue = (idx, value, key) => {
    dialogueList[idx][key]= value;
    editConsoleUnitTest();
  }

  const updateName = (val) => {
    setTestName(val);
  }

  const updateDescription = (val) => {
    setTestDescription(val);
  }

  useEffect(() => {
    editConsoleUnitTest();
  }, [testName, testDescription])

  const dialogue =  dialogueList.map((val, idx) => {
    if( val.type === "print") {
      return <PrintUnit
      data={val}
      idx={idx}
      key={val.order}
      remove={removeDialogue}
      update={updateDialogue}
      />
    } else {
      return <InputUnit
      data={val}
      idx={idx}
      key={val.order}
      remove={removeDialogue}
      update={updateDialogue}
      />
    }
    })

  return (
    <div className="border rounded p-2 mb-3">
      <input type={"text"} className="form-control mb-2"  placeholder="Test Name" value={testName} onChange={(e) => {updateName(e.target.value)}}/>
      <input type={"text"} className="form-control mb-2"  placeholder="Test Description" value={testDescription} onChange={(e) => {updateDescription(e.target.value)}}/>
      { dialogue }
      <button  className="btn btn-light mb-3" onClick={() => addDialogue(true)}><FaPlus/> Print Prompt</button>
      <button  className="btn btn-light mb-3 ml-3" onClick={() => addDialogue(false)}><FaPlus/> Input Prompt</button>
      <button onClick={deleteUnitTest} className="btn btn-light ml-3 mb-3"><FaTrash/></button>
    </div>
  )
}

const PrintUnit = ({data, idx, remove, update}) => {
  const [output, setOutput] = useState(data.output ?? "")

  const updateOutput = (val)  => {
    setOutput(val)
    update(idx, val, "output")
  }

    return (
        <div className="d-flex mt-3">
          <strong style={{minWidth: "80px"}}>Print</strong>
          <input type={"text"} className="form-control mb-2" placeholder={`Place expected output here!`} value={output} onChange={(e) => {updateOutput(e.target.value)}}/>
          <button className="btn btn-light mb-2" onClick={() => remove(idx)}><FaEraser/></button>
        </div>
    )
}

const InputUnit = ({data, idx, remove, update}) => {
  const [output, setOutput] = useState(data.output ?? "")
  const [input, setInput] = useState(data.input ?? "")

  useEffect(() => {
    update(idx, input, "input")

  }, [input])

  useEffect(() => {
    update(idx, output, "output")

  }, [output])

  const updateOutput = (val)  => {
    setOutput(val)
  }

  const updateInput = (val)  => {
    setInput(val)
  }



    return (
        <div className="d-flex mt-3">
          <strong style={{minWidth: "80px"}}>Input</strong>
          <input type={"text"} className="form-control mb-2" placeholder="Place Prompt!" value={output} onChange={(e) => {updateOutput(e.target.value)}}/>

          <input type={"text"} className="form-control mb-2" placeholder="Place input data!" value={input} onChange={(e) => {updateInput(e.target.value)}}/>
          <button className="btn btn-light mb-2" onClick={() => remove(idx)}><FaEraser/></button>
        </div>
    )
}



// const ConsoleUnitTest = ({unitTestData, serverAssnData,unitTests,deleteUnitTest, editUnitTest,index,setUnitTestIndex}) => {
//   const [outputList, setOutputList] = useState(unitTestData["post"].map((testStr, idx) => {return { text: testStr, id: Date.now() + idx}}))
//   const [inputList, setInputList] = useState(unitTestData["pre"].map((testStr, idx) => {return { text: testStr, id: Date.now() + idx}}))

//   // const [outputList, setOutputList] = useState([])
//   // const [inputList, setInputList] = useState([])
//   const [testName, setTestName] = useState(unitTestData.name)

//   const addIndex = ( output) => {
//     if(output) {
//       outputList.push({text :"", id: Date.now()})
//     }
//     else {
//       inputList.push({text :"", id: Date.now()})
//     }
//     editConsoleUnitTest()
//   }



//   const editConsoleUnitTest = () => {
//     editUnitTest("post", outputList.map((el)=>{return el.text}))
//     editUnitTest("pre", inputList.map((el)=>{return el.text}))
//     editUnitTest("name", testName)
//   }

//   const removeIndex = (index, output) => {

//     if(output) {
//       outputList.splice(index, 1);
//     } else {
//       inputList.splice(index, 1)
//     }
//     editConsoleUnitTest()
// }

// const updatePost = (idx, value, output) => {
//   if(output) {
//     outputList[idx].text = value
//   } else {
//     inputList[idx].text = value
//   }
//   editConsoleUnitTest()
// }


// const testInputList = inputList.map((input, idx) => {
//   return <TestList
//   idx={idx}
//   removeIndex={(idx) => removeIndex(idx, false)}
//   updatePost={(idx, value) => updatePost(idx, value, false)}
//   key={input.id}
//   recentVal={input.text}
//   id={input.id}
//   output={false}

//   />
// })

//   const testOutputList = outputList.map((output, idx) => {
//     return <TestList
//     idx={idx}
//     removeIndex={(idx) => removeIndex(idx, true)}
//     updatePost={(idx, value) => updatePost(idx, value, true)}
//     key={output.id}
//     recentVal={output.text}
//     id={output.id}
//     output={true}
//     />
//   })

//   return(
//   <div className="form-control mt-3 ">
//     <label><strong>Name: </strong></label>
//     <div className="d-flex">
//       <input type={"text"} className="form-control" placeholder={`Test Name`} value={testName} onChange={(e) => setTestName(e.target.value)}/>
//       <button onClick={deleteUnitTest} className="btn btn-dark"><FaTrash/></button>
//     </div>
//     <div className="mt-3">
//       <b>Test Input:</b>
//       {testInputList}
//       <button onClick={() => addIndex(false)} className="btn btn-light"><FaPlus/></button>
//     </div>
//     <div className="mt-3">
//       <b>Expected Output:</b>
//       {testOutputList}
//       <button onClick={() => addIndex(true)} className="btn btn-light"><FaPlus/>Output</button>
//       <button onClick={() => addIndex(true)} className="btn btn-light"><FaPlus/>Output</button>
//     </div>
//   </div>
//   )
// }

// const TestList = ({idx, removeIndex, updatePost, recentVal, id, output}) => {
//     const [content, setContent] = useState(recentVal);
//     const updateOutput = (e) => {
//       setContent(e.target.value)
//     }

//     useEffect(() => {
//         updatePost(idx, content)
//     }, [content])


//     return (
//         <div className="d-flex mt-3" key={id}>
//           <input type={"text"} className="form-control mb-2" placeholder={`Place ${output ? "expected output" : "test input"} here!`} value={content} onChange={updateOutput}/>
//           <button onClick={() => removeIndex(idx)} className="btn btn-light mb-2"><FaEraser/></button>
//         </div>
//     )
// }
