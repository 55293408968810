import React from 'react'
import './header.css'

import { Link } from "react-scroll";
import { RimMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png';



const Header = () => {

  return (
    <div className='csb__header'>
      <div className='row csb__header-links'>
        <div className='col-sm-3 csb__header-links_logo'>
          <a href="https://csbridge.stanford.edu/"><img src={logo} alt="logo" /></a>
        </div>
        <div className='col-sm-6 csb__header-links_container justify-content-center'>
          <p><Link activeClass="active" smooth spy to="csb-about" duration={100}>About</Link></p>
          <p><Link activeClass="active" smooth spy to="csb-courses" duration={100}>Courses</Link></p>
          <p><Link activeClass="active" smooth spy to="csb-projects" duration={100}>Projects</Link></p>
          <p><Link activeClass="active" smooth spy to="csb-news" duration={100}>News</Link></p>
        </div>
        <div className='col-sm-3'></div>
      </div>
    </div>
  )
}

export default Header;