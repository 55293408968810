
import { getApp } from "@firebase/app"
import { ProfileContext } from "contexts/ProfileContext"
import { doc, getFirestore, setDoc, updateDoc } from "firebase/firestore"
import { useUserId } from "hooks/user/useUserId"
import { useContext, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { FaCamera } from "react-icons/fa"
import { useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { uploadPhoto } from "utils/uploadFile"

const DEFAULT = ''

export const EditableImage = ({
  firebaseDocPath,
  imageStoragePath,
  editable,
  width,
  height
}) => {

  const db = getFirestore(getApp())
  const docRef = doc(db, firebaseDocPath)
  const [serverData, loading, error] = useDocumentData(docRef)
  const [isUploading, setIsUploading] = useState(false)

  const serverUrl = serverData?.url ? serverData.url : DEFAULT
  console.log(serverUrl)

  const onImageUploaded = (url) => {
    setDoc(docRef, { url }, { merge: true })
    setIsUploading(false)
    console.log(url)
  }

  const updateLoading = (progress) => {
    if (progress > 0 && progress < 100) {
      setIsUploading(true)
    }
  }

  const setErrorState = (error) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'File was not uploaded',
    })
    setIsUploading(false)
  }

  const changePhoto = () => {
    setIsUploading(false)
    uploadPhoto(imageStoragePath, onImageUploaded, updateLoading, setErrorState)
  }

  const style = {
    position: 'relative',
    backgroundImage: `url('${serverUrl}')`,
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height,
    width,
  }

  const editButtonStyle = {
    position: 'absolute',
    top: 10,
    right: 10,
  }

  return <>
    <div style={style}>
      {isUploading && <div className="spinner-grow" style={{ backgroundColor: 'white' }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}
      {editable && <button onClick={() => changePhoto()} className="btn btn-light" style={editButtonStyle}><FaCamera /></button>}
    </div>

  </>
}

const checkEditPermissions = (userId, section) => {
  console.log(section.sectionLeader, userId)
  return section.sectionLeader === userId
}

const makeBannerImg = (imgUrl, height, rounded) => {
  return {
    backgroundImage: `url('${imgUrl}')`,
    width: '100%',
    height: `${height}px`,
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderBottomLeftRadius: rounded ? 10 : 0,
    borderBottomRightRadius: rounded ? 10 : 0,
    position: 'relative',
    backgroundColor: '#eee',
  }
}

const editBannerButtonStyle = {
  position: 'absolute',
  top: 10,
  right: 10,
}