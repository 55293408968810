import { AssnEditorMain, createNewAssignment } from 'course/assnEditor/AssnEditor'
import { AssnSplashMain } from 'course/assnEditor/AssnEditorSplash'
import { useCourseId } from 'hooks/router/useUrlParams'
import { loadAssnData } from 'ide/hooks/loadIdeData'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

export const AssnEditorCSBridge = () => {
  const courseId = useCourseId()
  const { assnId } = useParams()
  if (!assnId || assnId == 'splash') {
    return <AssnEditorSplash />
  }
  const [isLoading, assnData] = loadAssnData(courseId, assnId, () => { })

  if (isLoading) {
    return <></>
  }
  return <AssnEditorPage
    serverAssnData={assnData}
    assnId={assnId}
    courseId={courseId}
  />
}

const AssnEditorPage = (props) => {
  const courseId = useCourseId()
  const { assnId } = useParams()
  return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <div className='container-lg'>
      <h2 className='mt-3'>
        <Link to={`/${courseId}/assneditor`}>Assn Editor</Link>: {assnId}
      </h2>
      <AssnEditorMain {...props} />
    </div>
  </div>
}

const AssnEditorSplash = () => {
  const courseId = useCourseId()
  return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <div className='container-lg'>
      <h2 className='mt-3'>
        <Link to={`/${courseId}/assneditor`}>Assn Editor</Link>
      </h2>
      <AssnSplashMain />
    </div>
  </div>
}
