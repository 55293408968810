import React, { useContext, useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { AssnContext } from 'course/contexts/AssnContext';


export const SortableAssnTable = ({ assnList, courseId, onOrderChange, deleteAssn }) => {
  const [items, setItems] = useState(assnList);

  useEffect(() => {
    setItems(assnList)
  }, [assnList])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
      >
        {items.map(id => <SortableItem
          key={id}
          id={id}
          courseId={courseId}
          deleteAssn={() => deleteAssn(id)}
        />)}
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        const newList = arrayMove(items, oldIndex, newIndex);
        onOrderChange(newList)
        return newList
      });
    }
  }
}

export function SortableItem(props) {
  const { assnMetaData } = useContext(AssnContext)
  console.log(assnMetaData)

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const assnId = props.id
  let assnName = 'Untitled'
  if(assnMetaData?.[assnId]) {
    assnName = assnMetaData[assnId].title
  }
  
  const editLink = `/${props.courseId}/assnEditor/${props.id}`

  return (
    <>
      <div className='d-flex justify-content-between w-100'>
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>

          {assnName}&nbsp;
          <span >({assnId})</span>
        </div>
        
        <span>
          <Button
            onClick={() => props.deleteAssn()}
            className='btn btn-light btn-sm mr-2'
          >
            <FaTrash className="" />
          </Button>
          <Link to={editLink}><FaEdit /></Link>
        </span>


      </div>
    </>
  );
}