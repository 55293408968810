
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useContext, useEffect, useState } from "react"
import { FaCode, FaEdit, FaEye, FaEyeSlash, FaLink, FaPlus, FaTrash } from "react-icons/fa"
import { Link, useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { CoursePageBodyContainer } from "../../components/layout/CoursePageBodyContainer"
import { fetchAssignmentInfo, fetchAssignments } from "../../utils/general"
import { AssnContext } from "../contexts/AssnContext"
import { createNewAssignment } from "./AssnEditor"
import { SortableAssnTable } from "./SortableAssnTable"
import { useCourseId } from "hooks/router/useUrlParams"


export const AssnEditorSplash = () => {
  return <CoursePageBodyContainer
    mainColumn={<AssnSplashMain />}
    rightColumn={<></>}
    singleColumn={<AssnSplashMain />}
  />
}

export const AssnSplashMain = () => {
  const { courseId } = useParams()
  const navigate = useNavigate()

  const { assignments, assnMetaData, assnLoaded } = useContext(AssnContext)

  useEffect(() => {
    console.log('assignments changed')
  }, [assignments])

  if (!assnLoaded) return <>loading</>

  const onAssnCreated = (assns, newAssnId) => {
    const assnEditUrl = `/${courseId}/assnEditor/${newAssnId}`
    navigate(assnEditUrl)
  }

  return <>
    {assignments.map(function (assn, i) {
      return <AssnSection
        assns={assignments}
        assnIndex={i}
        assn={assn}
        onOrderChange={(e) => saveAssns(assignments, courseId, i, e)}
        addNewAssn={() => addAssn(assignments, assnMetaData, courseId, i, onAssnCreated)}
        deleteAssn={(assnId) => deleteAssn(assignments, courseId, i, assnId, () => { })}
        courseId={courseId}
        key={i}
      />;
    })}
    <button className="btn btn-primary btn-sm" onClick={() => addSection(assignments, courseId, () => { })}><FaPlus /> New Section</button>
    <hr/>
    <div style={{height:50}}/>
    
    <UnlistedAssignmentsTable/>
  </>
}

const UnlistedAssignmentsTable= ()=>{
  const courseId = useCourseId()
  const { assnMetaData } = useContext(AssnContext)

  console.log(assnMetaData)
  return <>
  <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">All Assignments</div>
  
  {
    Object.keys(assnMetaData).map((assnId)=>{
      const editUrl = `/${courseId}/assnEditor/${assnId}`
      const title = assnMetaData[assnId].title
      return <><Link to={editUrl}>{title} ({assnId})</Link><br/></>
    })
  }
  <div style={{height:50}}/>
  </>
}

const AssnSection = ({ assns, assnIndex, assn, courseId, deleteAssn, onOrderChange, addNewAssn }) => {

  const isVisible = assn?.isVisible == true

  return <><div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">
    <span>{assn.title}</span>
    <div>
    <button
        onClick={() => setSectionVisibility(assns, courseId, assnIndex, !isVisible)}
        className="btn btn-light btn-sm">{isVisible ? "Unpublish" : "Publish"}

      </button>
      <button
        className="btn btn-light btn-sm"
        onClick={() => deleteSection(assns, courseId, assnIndex)}><FaTrash />
      </button>
      <button
        onClick={() => renameSection(assns, courseId, assnIndex)}
        className="btn btn-light btn-sm"><FaEdit />
      </button>

      
    </div>
  </div>
    <SortableAssnTable
      assnList={assn.assnList}
      courseId={courseId}
      onOrderChange={(newOrder) => { onOrderChange(newOrder) }}
      deleteAssn={(assnId) => deleteAssn(assnId)}
    />
    <button onClick={() => addNewAssn()} className="btn btn-light btn-sm"><FaPlus /> New Assn</button>
    <div style={{ height: 30 }} />

  </>
}

async function addSection(
  assns,
  courseId,
  callback
) {
  // get the name via a swal
  const { value: sectionName } = await Swal.fire({
    title: "New Section!",
    input: "text",
    inputLabel: "What is your section name",
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return "You can't have an empty section name!";
      }
    }
  });
  if (!sectionName) return;

  assns.push({
    title: sectionName,
    assnList: []
  });

  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns: assns }, { merge: true });
  callback(assns)

}

async function deleteSection(
  assns,
  courseId,
  assnIndex
) {
  await Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this section!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it"
  }).then(async (result) => {
    if (result.isConfirmed) {
      assns.splice(assnIndex, 1);
      const db = getFirestore();
      const assnRef = doc(db, `assns/${courseId}`);
      await setDoc(assnRef, { assns: assns }, { merge: true });
      Swal.fire("Deleted!", "Your section has been deleted.", "success");
    }
  })

}

async function deleteAssn(
  assns,
  courseId,
  assnIndex,
  deleteAssnId,
  callback
) {

  const oldList = assns[assnIndex].assnList
  const newList = []
  for (const assnId of oldList) {
    if (assnId != deleteAssnId) {
      newList.push(assnId)
    }
  }
  assns[assnIndex].assnList = newList

  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns: assns }, { merge: true });
  callback(assns)
}

async function renameSection(
  assns,
  courseId,
  assnIndex
) {
  const { value: newTitle } = await Swal.fire({
    title: 'Rename Section',
    input: 'text',
    inputValue: assns[assnIndex].title,
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return 'You need to write something!'
      }
    }
  })
  assns[assnIndex].title = newTitle
  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns }, { merge: true });
}

async function setSectionVisibility(
  assns,
  courseId,
  assnIndex,
  newVisibility
) {
  assns[assnIndex].isVisible = newVisibility
  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns }, { merge: true });
}

async function addAssn(
  assns,
  assnMetaData,
  courseId,
  assnIndex,
  callback
) {

  const { value: newAssnId } = await Swal.fire({
    title: "New Assignment!",
    input: "text",
    inputLabel: "What is your assignment id",
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return "You can't have an empty assnId!";
      }
    },
  });

  if(!(newAssnId in assnMetaData)) {
    await createNewAssignment(
      courseId,
      newAssnId, () => { }
    )
  }
  

  const insert = assns
  const newList = assns[assnIndex].assnList
  newList.push(newAssnId)
  assns[assnIndex].assnList = newList

  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns: insert }, { merge: true });
  callback(assns, newAssnId)
}


async function saveAssns(
  assns,
  courseId,
  assnIndex,
  assnList,
) {

  const insert = assns
  assns[assnIndex].assnList = assnList
  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}`);
  await setDoc(assnRef, { assns: insert }, { merge: true });
}