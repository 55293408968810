import { collection, doc, getDoc, getFirestore, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";

/**
 * Assignments are reactive to the firebase, so if you change
 * the value there, everyones assignment list will update!
 */

// lessons documentation is here:
// https://docs.google.com/document/d/14tDLbvJIJL6ftA9_xBqwawas_1klCLhrPVY_6WwyX5c/edit

const defaultData = {
  handouts:[],
  handoutsLoading: true,
  handoutsError: null
};

export const HandoutsContext = React.createContext(defaultData);
export const HandoutsProvider = ({ courseId, children }) => {

  const db = getFirestore()
  const docRef = doc(db, `course/${courseId}/handouts/doc`);  
  const [handouts, handoutsLoading, handoutsError] = useDocumentData(docRef)

  console.log('handoutsLoading',handoutsLoading)

  let safe = handouts?.handouts || []

  return (
    <HandoutsContext.Provider
      value={{
        handouts:safe,
        handoutsLoading,
        handoutsError
      }}
    >
      {children}
    </HandoutsContext.Provider>
  );
};
