import { Message } from 'firebase/realtime/types';
import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, InputGroup } from 'react-bootstrap';
import { FaPaperPlane } from 'react-icons/fa';
import Markdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm'





interface Props {
  // the user id of the current user. Chat will be from their perspective
  userId: string;
  messages: Message[];
  onSendMessage: (msg: string) => Promise<any>;
}

export const ChatRoom = ({ userId, messages, onSendMessage }: Props) => {

  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <div style={{ flex: '10', overflowY: 'scroll', borderBottom: '1px solid lightgray', padding: "10px 1px 0px 10px" }}>
        <MessageList messages={messages} userId={userId} />
      </div>
      <div>
        <MessageInput onSendMessage={onSendMessage} />
      </div>
    </div>
  )
}

export const MessageList = ({ messages, userId }: { messages: Message[], userId: string }) => {
  /*
   * Display the messages as chat bubbles, with the sender's name under the
   * bubble. The current user's messages will be on the right, and other users
   * messages will be on the left.
   *
   * This function also scrolls to the bottom of the chat when a new message
   * is added.
  */

  const messagesEndRef = React.useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(scrollToBottom, [messages]);

  return (
    <>

      {messages.map((msg, i) => {
        const isCurrentUser = msg.senderId === userId;
        const isCourseStaff = msg.senderId === 'simba';
        console.log('isCurrentUser', isCurrentUser, msg.senderId, userId)
        console.log(msg)
        return (
          <MessageBubble key={i} message={msg} isCurrentUser={isCurrentUser} isAdmin={isCourseStaff}
            prevMessage={i > 0 ? messages[i - 1] : null} />
        )
      })}
      <div ref={messagesEndRef} />
    </>
  )
}

const MessageBubble = ({ message, isCurrentUser, isAdmin, prevMessage }:
  { message: Message, isCurrentUser: boolean, isAdmin: boolean, prevMessage: Message }) => {
  /*
  * Display a single message as a chat bubble. If isCurrentUser = true,
  * messages will be on the right, otherwise messages will be on the left.
  *
  * The sender's name will be displayed under the bubble.
  */
  const dispName = message.senderName
  const marker = isAdmin ? "🌱" : "🎓"
  const dispNameWithMarker = isCurrentUser ? `🌻 You` : `${marker} ${dispName}`
  console.log("Message: ", message)
  const ts = message.timestamp ? message.timestamp : message.timestamp;
  const tsPrev = prevMessage?.timestamp ? prevMessage.timestamp : prevMessage?.timestamp;
  const time = new Date(ts)
  const timeOnly = time.toLocaleTimeString('en-US', {
    hour: '2-digit', minute: '2-digit', hour12: true
  });


  const isFirstMessage = prevMessage === null
  const isContinuation = !isFirstMessage &&
    prevMessage.senderId === message.senderId &&
    (ts - tsPrev) < 60 * 1000;

  return (
    <>
      {/* { isFirstMessage || isContinuation ? null : <hr style={{width: "90%", border: "0.5px solid gray", margin: "auto"}}/> } */}
      {isFirstMessage || isContinuation ? null : <div style={{ width: "100%", marginTop: "1.5rem" }} />}
      <div>
        <div style={{ width: "100%" }}>
          {
            isContinuation ?
              null
              :
              <div className="mt-3" style={{ display: "flex", "justifyContent": "space-between" }}>
                <div style={{ fontWeight: "bold" }}>{dispNameWithMarker}</div>
                <div><small style={{ marginLeft: "10px", color: "lightgray", fontSize: "12px" }}>{timeOnly} </small></div>
              </div>
          }
          <div style={{ fontStyle: isAdmin ? "italic" : "normal" }}>
            <MessageWithMarkdown message={message.text} />
          </div>
        </div>
      </div>

    </>
  )
}

export const MessageWithMarkdown = ({ message }: { message: string }) => {
  return (
    <div style={{
      width: "100%",
      // this should only overflow for links which we are okay clipping
      // since they are clickable
      overflowX: "clip",
    }}>
      <Markdown
        allowedElements={['a', 'p', 'code', 'pre', "li", "ol", "ul", "strong", "em", "blockquote", "hr"]}
        unwrapDisallowed={true}
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ node, ...props }) => <a {...props} target="_blank" />,
          code: ({ node, inline, className, children, ...props }) => {
            // default to python
            const match = /language-(\w+)/.exec(className || 'language-python')
            return !inline && match ? (
              <SyntaxHighlighter
                language={match[1]}
                PreTag="div"
                children={String(children).replace(/\n$/, '')}
                // style={dark}
                {...props} />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            )
          },
          // pre: ({node, children, ...props}) => <pre {...props} style={{whiteSpace: 'pre-wrap'}}>{children}</pre>
        }}
      >
        {message}
      </Markdown>
    </div>
  )
}




export const MessageInput = ({ onSendMessage }: { onSendMessage: (msg: string) => void }) => {
  const MAX_ROWS = 4;
  const [text, setText] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }

  useEffect(() => {
    // Scroll textarea to bottom when text changes
    inputRef.current.scrollTop = inputRef.current.scrollHeight;

    // const MAX_HEIGHT = 200;
    // @ts-ignore
    // setTextAreaHeight(Math.min(inputRef.current.scrollHeight, MAX_HEIGHT));

  }, [text])


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSendMessage(text);
    setText('');
  }

  const computeNumRows = (text: string) => {
    const lines = text.split("\n");
    return Math.min(lines.length, MAX_ROWS);
  }

  const inputRef = React.useRef(null);


  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Send the message when user preseses Cmd+Enter or Ctrl+Enter (Mac and Windows)
    // console.log(event)
    if (event.shiftKey && event.key === 'Enter') {
      // Enter newline
      event.preventDefault();
      setText(text + '\n');

    } else if (event.key == 'Enter') {
      handleSubmit(event as any);
    }
  }
  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%', padding: "10px 5px 5px 5px" }}>
      <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <FormGroup>
          <InputGroup>
            <Form.Control
              as="textarea"
              rows={computeNumRows(text)}
              value={text}
              onChange={handleChange}
              ref={inputRef}
              onKeyDown={handleEnterSend}
              style={{
                resize: 'none',
                height: 'auto',
                marginRight: '5px'
              }}
            />
            <div style={{ marginTop: 'auto' }}>
              <Button style={{ display: "block", margin: 'auto', height: '35px', fontSize: '15px' }} type="submit">
                <FaPaperPlane />
              </Button>
            </div>

          </InputGroup>
        </FormGroup>
      </Form>
    </div>
  )
}
