/** Important: we are going to change this whole file to use lazy load! */

// import firebase from "firebase";
import React, { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// import the styles
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.scss";

// import authentication first

import { FirebaseAuthProvider } from "../components/auth/FirebaseAuthProvider";
import firebase from "firebase/compat/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseConfig } from "../firebase/config";
import { database } from "firebaseApp.js";

import ErrorBoundary from "components/errors/ErrorBoundary";
import { Landing } from "./landing/Landing";
import PyodideProvider from "components/pyodide/PyodideProvider";
import { Authenticated } from "components/auth/Authenticated";
import { Splash } from "./course/pages/Splash";
import { AssnEditorCSBridge } from "./course/editors/AssnEditor";
import { CSBridgeForum } from "./forum/CSBridgeForum";
import { CoursePage } from "./course/components/CoursePage";
import { CourseWrapper } from "./course/components/CourseWrapper";
import { IDEWithProvider } from "ide/IDE";
import { Published } from "publishedCode/Published";
import { CodeCenter } from "./course/codecenter/CodeCenter";
import { TextbookPage } from "textbook/TextbookPage";
import { LessonEditorCSBridge } from "./course/editors/LessonEditor";
import { Lesson } from "./course/pages/Lesson";
import { SetPermissionsPage } from "course/setPermissions/SetPermissionsPageSimple";
import { WorkedExamplesEditor } from "./course/editors/WorkedExamplesEditor";
import { HandoutEditor } from "./course/editors/HandoutEditor";
import { DocsEditor } from "course/editors/docsEditor/DocsEditor";
// import { HandoutInner, HandoutPage } from "course/handout/HandoutPage";
import { Handout } from "./course/pages/Handout";
import { FileHistory } from "ide/fileHistory/FileHistory";


const DocsEditorContainer = () => {
  return <div className="container-lg">
    <DocsEditor />
  </div>
}

const SetPermissionsContainer = () => {
  return <div className="container-lg">
    <SetPermissionsPage />
  </div>
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />}>

      <Route index element={<Landing />} />

      <Route path=":courseId" element={<Authenticated component={CourseWrapper} />}>

        <Route path="ide/:urlFormat/:urlKey" element={<IDEWithProvider />} />
        <Route path="ide/history/:projectId/:fileId" element={<FileHistory />}/>
        <Route path="share/:projectId" element={<Published />} />

        <Route path="*" element={<CoursePage />}>
          <Route index element={<Splash />} />
          <Route path="assneditor/:assnId?" element={<AssnEditorCSBridge />} />
          <Route path="lessoneditor" element={<LessonEditorCSBridge />} />
          <Route path="handouteditor" element={<HandoutEditor />} />
          <Route path="workedexampleseditor" element={<WorkedExamplesEditor />} />
          <Route path="docseditor/:docsId?" element={<DocsEditorContainer />} />
          <Route path="setpermissions" element={<SetPermissionsContainer />} />
          <Route path="forum" element={<CSBridgeForum />} />
          <Route path="code" element={<CodeCenter />} />
          <Route path="textbook/:chapterId?" element={<TextbookPage />} />
          <Route path="lesson/:lessonIndex?" element={<Lesson />} />
          <Route path="handout/:handoutId" element={<Handout />} />


        </Route>
      </Route>
    </Route>
  )
);

export const Router = () => {
  return <RouterProvider router={router} />;
};

