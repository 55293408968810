import firebase from "firebase/compat/app";
import Swal from "sweetalert2";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

export const uploadPDF = (storagePath, onComplete, onUploadProgress, onErrorState) => {
    Swal.fire({
        title: "Select file",
        input: "file",
        inputAttributes: {
        accept: "application/pdf",
        "aria-label": "Upload your PDF",
        },
    }).then((e) => {
        const file = e.value;
        if (file) {
            uploadVideoFile(file, storagePath, onComplete, onUploadProgress, onErrorState);
        }
    });
}

const uploadVideoFile = (file, storagePath, setVideoUrl, setUploadProgress, setErrorState) => {

    // lets get this started
    setUploadProgress(1)
    
    const storage = firebase.storage();
    const storageRef = storage.ref();
    
    const uploadTask = storageRef.child(storagePath).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress(progress);
      },
      (error) => {
        setErrorState({
            code: error.code,
            message: error.message
        })
      },
      () => {
        // get a playable url from the snapshop ref
        const ref = uploadTask.snapshot.ref
        ref.getDownloadURL().then((url) => {
            setVideoUrl(url);
        })
      }
    );
  };
