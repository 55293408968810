// @ts-nocheck
import { Role } from "contexts/ProfileContext"
import { roleToFirebaseString } from "contexts/ProfileUtil"
import { getFunctions, httpsCallable } from "firebase/functions"
import { functions } from "firebaseApp"
import { useCourseId } from "hooks/router/useUrlParams"
import { FaPlus } from "react-icons/fa"
import Swal from "sweetalert2"

export const SetPermissionsPage = () => {
  const courseId = useCourseId()

  const addAdmin = () => {
    // fire a swal to get the email address of the admin
    // then add the admin to the course
    Swal.fire({
      title: "Add Admin",
      input: "email",
      inputLabel: "Email Address",
      inputPlaceholder: "Enter the email address of the admin"
    }).then((result) => {
      if (result.isConfirmed) {
        addAdminWithEmail(result.value)
      }
    })
  }

  const addAdminWithEmail = async (email) => {
    const addUserPermsFirebase = httpsCallable(getFunctions(), "addUserPermsNew");
    // displayName will only be used if the user is new
    const newUsers = [
      {
        email,
        displayName: "Section Leader" 
      }
    ]
    const isMinor = false;
    const roleStr = roleToFirebaseString(Role.ADMIN);
    console.log("Add admin with email: ", email)
    const res = await addUserPermsFirebase({newUsers, courseId, newRole: roleStr, isMinor})
    console.log("Add admin response: ", res)
    const isSuccess = res.data.success
    if(isSuccess) {
      Swal.fire({
        title: "Admin Added",
        text: `${email} has been added as an admin to the course.`,
        icon: "success"
      })
    } else {
      Swal.fire({
        title: "Error",
        text: `There was an error adding ${email} as an admin to the course.`,
        icon: "error"
      })
    }
  }


  return <div className="mt-4">
    <button className="btn btn-primary" onClick={() => addAdmin()}><FaPlus /> Add Admin</button>
  </div>
}