import { collection, doc, getDoc, getFirestore, query, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { functions } from "firebaseApp";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { fetchAllAssignmentMetaData, fetchAssignments } from "../utils/general";
import { getAuth } from "firebase/auth";
import { useUserId } from "hooks/user/useUserId";
import { getApp } from "firebase/app";
import { TimeContext } from "contexts/TimeContext";
import { useNavigate } from "react-router";
import { useCourseId } from "hooks/router/useUrlParams";

const defaultData = {
  hasStarted: false,
  diagnosticLoading: true,
  timeLeft:null,
  startExam: () => {},
};

export const DiagnosticContext = React.createContext(defaultData);
export const DiagnosticProvider = ({children}) => {
  const courseId = useCourseId()
  const userId = useUserId()
  const db = getFirestore(getApp())
  const docRef = doc(db, `users/${userId}/${courseId}/diagnostic` )
  const navigate = useNavigate()

  const {getServerTimeMs} = useContext(TimeContext)
  const [diagnosticData, diagnosticLoading, diagnosticError] = useDocumentData(docRef)
  const [timeLeft, setTimeLeft] = useState(null)
  const hasStarted = diagnosticData?.hasStarted ?? false


  const startExam = () => {
    // write to the docRef and include a timestamp
    setDoc(docRef, {
      hasStarted: true,
      startedAt: firebase.firestore.FieldValue.serverTimestamp()
    }, {merge: true})
  }

  const checkTimeLeft = () => {
    // calculation is in seconds
    if(!diagnosticData.startedAt) {
      return
    }
    const nowMs = getServerTimeMs()
    if(!nowMs){
      return
    }
    const nowSec = nowMs / 1000
    const startedAt = diagnosticData.startedAt.seconds
    
    const examSeconds = 60 * 60 * 3
    const elapsedSeconds = nowSec - startedAt
    const timeLeft = examSeconds - elapsedSeconds

    if(timeLeft < 0) {
      navigate(`/${courseId}/diagnostic/finished`)
    }

    setTimeLeft(timeLeft)
  }

  useEffect(()=>{
    if(hasStarted) {
      // do it as soon as data is loaded
      checkTimeLeft()
      // create a timer which runs once a second
      const timer = setInterval(()=>{
        checkTimeLeft()
      }, 1000)
      return () => {
        clearInterval(timer)
      }
    }
  }, [diagnosticData])

  

  return (
    <DiagnosticContext.Provider
      value={{
        startExam,
        diagnosticLoading,
        hasStarted,
        timeLeft
      }}
    >
      {children}
    </DiagnosticContext.Provider>
  );
};

