import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaComment, FaPython } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import { courseInfo } from "cs106a/courseInfo";
import { loginWithRedirect } from "cs106a/authentication/Login";

export const CS106ANavbar = () => {
    return (
        <Navbar bg="dark" variant="dark" expand="md" sticky="top">
            <div className="container-lg">
                <LinkContainer className="text-light" to={"/"}>
                    <Navbar.Brand>CS106A</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <LeftNav />
                    <RightNav />
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

const LeftNav = () => {
    return (
        <Nav className="me-auto">
            <CourseDropdown />
            <LecturesDropdown />
            <AssignmentsDropdown />
            <SectionsDropdown />
        </Nav>
    );
};

const LecturesDropdown = () => {
    // conditionally render the lectures (so you don't have to update the nav manually)
    // you can still directly go to the lectures via a link eg cs106a.stanford.edu/lectures/2
    const lectureSchedule = courseInfo.lectureSchedule
    return <NavDropdown title="Lectures" id="lectures-dropdown">

        {/* Loop over each lecture in the lectureSchedule */}
        {Object.keys(lectureSchedule).map(lectureId => {
            // extract the date
            const lectureDate = lectureSchedule[lectureId]

            // check if its an hour away (or less)
            if (isCloseToLecture(lectureDate)) {

                // if so, show the lecture link
                return <LinkContainer to={`/lecture/${lectureId}`} key={lectureId}>
                    <NavDropdown.Item >Lecture {lectureId}</NavDropdown.Item>
                </LinkContainer>
            }
            return null
        })}
    </NavDropdown>
};

const AssignmentsDropdown = () => {
    return (
        <NavDropdown title="Assignments" id="assignments-dropdown">
            <LinkContainer to="/assn0">
                <NavDropdown.Item>Assn 0: Who are you?</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/assn1">
                <NavDropdown.Item>Assn 1: Karel the Robot</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/assn2">
                <NavDropdown.Item>Assn 2: Khansole Academy and Computing Interest</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/assn3">
                <NavDropdown.Item>Assn 3: Breakout</NavDropdown.Item>
            </LinkContainer>
        </NavDropdown>
    );
};

const SectionsDropdown = () => {
    return (
        <NavDropdown title="Sections" id="sections-dropdown">
            <NavDropdown.Item href="https://cs198.stanford.edu/cs198/">Section Portal</NavDropdown.Item>
            <NavDropdown.Divider />
            <LinkContainer to="/section1">
                <NavDropdown.Item>Section 1: Karel the Robot</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/section2">
                <NavDropdown.Item>Section 2: Variables & Control Flow</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/section3">
                <NavDropdown.Item>Section 3: Parameters, Returns, and Graphics</NavDropdown.Item>
            </LinkContainer>
        </NavDropdown>
    );
};

const CourseDropdown = () => {
    return (
        <NavDropdown title="Course" id="course-dropdown">
            <LinkContainer to="/syllabus">
                <NavDropdown.Item>Syllabus</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/schedule">
                <NavDropdown.Item>Schedule</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/honor-code">
                <NavDropdown.Item>Honor Code</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/installing-pycharm">
                <NavDropdown.Item>Installing PyCharm</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/pycharm-bugs">
                <NavDropdown.Item>PyCharm Bugs</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/karel-pycharm">
                <NavDropdown.Item>Using Karel with PyCharm</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/graphics">
                <NavDropdown.Item>Graphics Reference</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/interactive-programs">
                <NavDropdown.Item>Interactive Programs Reference</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/style">
                <NavDropdown.Item>Style Guide</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/images">
                <NavDropdown.Item>Image Reference</NavDropdown.Item>
            </LinkContainer>
        </NavDropdown>
    );
};

const RightNav = () => {
    const navigate = useNavigate();
    return (
        <Nav className="ml-auto">
            <button
                onClick={() => navigate("/spr23/forum")}
                className="btn btn-sm btn-dark mr-0 text-muted"
            >
                <FaComment size={20} />
            </button>
            <button
                onClick={() => navigate("/spr23/code")}
                className="btn btn-sm btn-dark text-muted"
            >
                <FaPython size={20} />
            </button>
            {/* <Nav.Link href="#home"><FaThLarge/></Nav.Link> */}
            {/* Profile dropdown with logout */}
            <SignInSignOut />
        </Nav>
    );
};

const SignInSignOut = () => {
    const auth = getAuth(getApp());
    const [user, loading] = useAuthState(auth);
    if (loading) {
        return <Nav.Link>Loading...</Nav.Link>;
    }
    if (!user) {
        return <Nav.Link onClick={() => loginWithRedirect()}>Sign In</Nav.Link>;
    }
    return (
        <>
            {user && (
                <NavDropdown title={getFirstName(user)} id="profile-dropdown">
                    <NavDropdown.Item onClick={() => signOut(auth)}>
                        Sign Out
                    </NavDropdown.Item>
                </NavDropdown>
            )}
        </>
    );
};

const getFirstName = (user) => {
    if (!user) return "";
    if(!user.displayName) return "Anonymous"
    return user.displayName.split(" ")[0];
}

const isCloseToLecture = (lectureDate) => {
    const now = new Date()
    const lecture = new Date(lectureDate)
    const pacificDateString = lecture.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });
    const lectureDatePacificTime = new Date(pacificDateString);

    // get local time in PT
    const nowPacificDateString = now.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });
    const nowDatePacificTime = new Date(nowPacificDateString);

    // check the diff in PT
    const diffMs = lectureDatePacificTime - nowDatePacificTime
    const diffHours = diffMs / (1000 * 60 * 60)

    // show the lecture if its less that one hour away
    return diffHours < 1
}
