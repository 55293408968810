import { useEffect, useRef, useState } from "react";

export const Handout = ({element}) => {

    const [height, setHeight] = useState('100vh');

    const handoutRef = useRef(null);

    useEffect(() => {
        const handoutHeight = handoutRef.current.offsetHeight;
        const newHeight = `max(${handoutHeight}px, 100vh - 56px)`;
        setHeight(newHeight);
    }, [element]);

    return (
        <div className="greyBackground pb-4" style={{width: '100vw', minHeight: height}}>
            <div className="container-fluid">
                <div className="row handoutRow">
                    <div className="col handout" ref={handoutRef}>
                        <div className="pb-2 mb-2">
                            {element}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
