import PyodideProvider from "components/pyodide/PyodideProvider";
import { Outlet } from "react-router";
import { ProfileContext, ProfileProvider } from "contexts/ProfileContext";
import { useContext } from "react";
import { CSBridgeNavbar } from "./Navbar";

import '../styles/csbridge.scss'
import { AssnProvider } from "course/contexts/AssnContext";
import { useCourseId } from "hooks/router/useUrlParams";
import { LessonsProvider } from "../contexts/LessonsContextCSBridge";
import { ExamplesProvider } from "../contexts/ExamplesContext";
import { HandoutsProvider } from "../contexts/HandoutsContext";
import { NavigationHistoryProvider } from "contexts/NavigationContext";
import { CompletionProvider } from "contexts/CompletionContext";

const validCourses = {
  'uniandes23': true,
  'uniandes24': true,
  'auw23': true,
  'cs105fall23': true
}

export const CourseWrapper = () => {
  const courseId = useCourseId()
  if (!(courseId in validCourses)) {
    return <>Invalid course: {courseId}</>
  }
  return <ProfileProvider>
    <LessonsProvider courseId={courseId}>
      <NavigationHistoryProvider>
        <CompletionProvider courseId={courseId}>
          <HandoutsProvider courseId={courseId}>
            <ExamplesProvider courseId={courseId}>
              <AssnProvider courseId={courseId}>
                <PyodideProvider>
                  <CoursePageInner />
                </PyodideProvider>
              </AssnProvider>
            </ExamplesProvider>
          </HandoutsProvider>
        </CompletionProvider>
      </NavigationHistoryProvider>
    </LessonsProvider>
  </ProfileProvider>
}

const CoursePageInner = () => {
  const { loading: userIsLoading, userData } = useContext(ProfileContext)
  if (userIsLoading) return <></>;
  return <Outlet />
}