import { Role } from "contexts/ProfileContext";
import { ForumContext } from "course/forum/ForumContext";
import { Forum } from "course/forum/pages/forum/Forum";
import { useCourseId } from "hooks/router/useUrlParams";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
export const CSBridgeForum = () => {
  const courseId= useCourseId()
  const forumId = `csbridge_${courseId}_main`
  return (
      <ForumContext.Provider
        value={{
          forumId: forumId,
          forumType: "Course",
          privatePostThreshold: Role.SECTION_LEADER,
          setPinnedThreshold: Role.ADMIN,
        }}
      >
        <QueryClientProvider client={queryClient}>
        <Forum/>
        </QueryClientProvider>
      </ForumContext.Provider>
  );
};