import { Dropdown } from "react-bootstrap";
import { FaBold, FaCode, FaHeading, FaImage, FaLink, FaMap, FaPaintBrush, FaPython, FaQuoteLeft, FaSquare, FaSquareRootAlt, FaTerminal, FaVideo } from "react-icons/fa";
import FaKarel from "../../../pyodide/KarelLib/images/classic/karelEast.png"
import { onInsertImage } from "../extensions/Image";
import { useUserId } from "hooks/user/useUserId";
import { createEditableLink } from "../extensions/EditableLink";

export const AssnEditorButtonBar = ({ editor, editable }) => {

  const addYoutubeVideo = () => {
    editor.chain().focus().insertContent(`<iframe width="200" height="100" src="https://www.youtube.com/embed/_aVj78s_SwQ?si=niZXD2OF9O0kz9tV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`).run()
    // editor.chain().focus().setYoutubeVideo({
    //   src: '_aVj78s_SwQ',
    //   width: 640,
    //   height: 480,
    // }).run()
  }

  // users need to be logged in only if they are going to upload images
  const userId = useUserId()
  const textStyleColor = editor?.getAttributes('textStyle')?.color
  if (!editable) return <></>;
  return (
    <div
      className="d-flex align-items-center"
      style={{
        top: "0",
        background: "white",
        border: "1px solid lightgrey",
        borderRadius: "5px 5px 0px 0px",
      }}
    >
      <button
        onClick={() =>
          editor.chain().focus()
            .insertContent("<karelworld></karelworld><p></p>")
            .run()
        }
        className="btn btn-sm btn-light"
      >
        <FaMap />
      </button>


      <button onClick={() => onInsertImage(editor, userId)} className="btn btn-sm btn-light">
        <FaImage />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={"btn btn-sm btn-light "}
      >
        <FaSquare />
      </button>

      <button
        onClick={() => {
          editor.chain().focus().setNode('terminal').run();
        }}
        className="btn btn-sm btn-light"
      >
        <FaTerminal /> {/* Assuming you have an icon component FaTerminal */}
      </button>


      <button onClick={() => editor.chain().focus().insertContent('<karelworld></karelworld>').run()} className='btn btn-sm btn-light'><FaPaintBrush /></button>

      <button
        onClick={() => createEditableLink(editor)}
        className={"btn btn-sm btn-light "}
      >
        <FaLink />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={"btn btn-sm btn-light "}
      >
        <FaQuoteLeft />
      </button>

      {/* see the final example here https://casesandberg.github.io/react-color/ */}

      <input
        type="color"
        style={{ width: 25, height: 25 }}
        onInput={event => editor.chain().focus().setColor(event.target.value).run()}
        value={textStyleColor ? textStyleColor : 'black'}
      />

      <button
        onClick={() => editor.chain().focus().insertContent("<h3></h3>").run()}
        className={"btn btn-sm btn-light "}
      >
        <FaHeading />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={"btn btn-sm btn-light "}
      >
        <FaCode />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className="btn btn-sm btn-light mr-1"
      >
        <FaBold />
      </button>

      {/* <button onClick={() => editor.chain().focus().insertContent('<console-demo></console-demo>').run()} className='btn btn-sm btn-light'><FaTerminal/> Demo</button> */}

      {/* <Dropdown>
          <Dropdown.Toggle size="sm" variant="light" id="dropdown-basic">
            <FaHeading />
          </Dropdown.Toggle>
  
          <Dropdown.Menu >
            <Dropdown.Item
              onClick={() =>
                editor.chain().focus().insertContent("<h1></h1>").run()
              }
            >
              Title
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                editor.chain().focus().insertContent("<h3></h3>").run()
              }
            >
              Subtitle
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

      
    </div>
  );
};