import { CompletionContext } from "contexts/CompletionContext"
import { Role } from "contexts/ProfileContext"
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor"
import { AssnContext } from "course/contexts/AssnContext"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext } from "react"
import { Link } from "react-router-dom"
import {Checkmark} from "react-checkmark"

export const Splash = () => {
  const courseId = useCourseId()

  return <>
    <div className="container-lg">

      <div className="mt-3"></div>
      <AdminRichTextEditor
        firebaseDocPath={`course/${courseId}/handouts/splashintro`}
        minRole={Role.ADMIN}
      />

      <ProgramList />

      <AdminRichTextEditor
        firebaseDocPath={`course/${courseId}/handouts/splashoutro`}
        minRole={Role.ADMIN}
      />

    </div>
  </>
}

const ProgramList = () => {

  const { assignments, assnMetaData } = useContext(AssnContext)

  return <>

    <table className="table table-hover">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          {/* <th>Topic</th> */}
        </tr>
      </thead>
      <tbody>

        {
          assignments.map((assn, i) => {
            const isVisible = assn.isVisible
            if(!isVisible)  return <></>
            return <Section key={`section${i}`} assn={assn} assnIndex={i} />
          })
        }

      </tbody>
    </table>
  </>
}

const Section = ({ assn, assnIndex }) => {
  const courseId = useCourseId()
  const { assnMetaData } = useContext(AssnContext)

  const {assnProgress} = useContext(CompletionContext)

  console.log(assnProgress)

  return <>
    <tr>
      <th colSpan={6}><b><br />[{assn.title}]</b></th>
    </tr>
    {
      assn.assnList.map((assnId, i) => {
        const assnData = assnMetaData[assnId]
        const ideLink = `/${courseId}/ide/a/${assnId}`

        const isComplete = assnId in assnProgress


        const projectImgStyle = {
          position: 'relative',
          backgroundImage: `url('${assnData.url}')`,
          backgroundPosition: '50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '150px',
          height: '100px'
        }

        const from = `/${courseId}`
        return <tr key={`assn${assnId}`}className="table-bordered table-striped">
          <td><div style={projectImgStyle}></div></td>
          <td><Link 
            to={ideLink}
            state={{from}}
            >{assnData.title}</Link></td>
          <td>{isComplete && <Checkmark size="medium"/>}</td>
        </tr>

      })
    }
  </>
}


