import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { CoursePageBodyContainer } from "../../components/layout/CoursePageBodyContainer"
import { loadAssnData } from "../../ide/hooks/loadIdeData"
import { addDoc, collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { TipTap } from "../../components/richTextEditor/TipTap/TipTap";
import { DropdownButton, Dropdown } from "react-bootstrap";
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/python';
import { AssnEditorSplash } from "./AssnEditorSplash";
import { EditStarterWorld } from "./editors/EditStarterWorld";
import { EditUnitTests } from "./editors/EditUnitTests";
import { AssnEditorButtonBar } from "components/richTextEditor/TipTap/buttonbars/AssnEditorButtonBar";
import { EditableImage } from "components/editableImage/EditableImage";

export const AssnEditor = (props) => {
  const { courseId, assnId } = useParams()
  if (!assnId || assnId == 'splash') {
    return <AssnEditorSplash />
  }
  const [isLoading, assnData] = loadAssnData(courseId, assnId, () => {
    createNewAssignment(
      courseId,
      assnId,
      () => { }
    )
  })

  if (isLoading) {
    return <></>
  }

  return <CoursePageBodyContainer
    mainColumn={<AssnEditorMain
      serverAssnData={assnData}
      assnId={assnId}
      courseId={courseId}
    />}
    rightColumn={<></>}
    singleColumn={<AssnEditorMain
      serverAssnData={assnData}
      assnId={assnId}
      courseId={courseId}
    />}
  />

}

export const AssnEditorMain = (props) => {

  // karel, console, graphics...
  const [typeCache, setTypeCache] = useState(props.serverAssnData.metaData.type)
  useEffect(() => {
    setTypeCache(props.serverAssnData.metaData.type)
  }, [props.serverAssnData])

  const childProps = {
    ...props,
    typeCache,
    setTypeCache
  }

  return <>
    <AssnEditorMeta{...childProps} />
    <hr />
    <AssnEditorPrompt{...childProps} />
    <hr />
    <EditStarterCode{...childProps} />
    <EditUnitTests{...childProps} />
    <div style={{ height: 20 }} />
  </>
}



const EditStarterCode = ({ serverAssnData, courseId, assnId }) => {
  let currCode = serverAssnData?.starterCode

  const [code, setCode] = useState(currCode ? currCode['main.py'] : '')
  const [dirtyBit, setDirtyBit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  function onUpdate(newCode) {
    setDirtyBit(true)
    setCode(newCode)
  }

  function saveStarterCode() {
    setIsSaving(true)
    updateStarterCode(courseId, assnId, code, () => {
      setDirtyBit(false)
      setIsSaving(false)
    })
  }

  let buttonTxt = isSaving ? 'Saving...' : 'Save'
  buttonTxt = dirtyBit ? 'Save' : 'Saved'
  return <div className='d-flex flex-column mt-3'>
    <h3>Starter Code</h3>

    <AceEditor
      mode="python"
      value={code}
      onChange={(e) => onUpdate(e)}
      fontSize="15px"
      enableBasicAutocompletion={true}
      enableLiveAutocompletion={true}
      highlightActiveLine={false}
      showGutter={true}
      wrapEnabled={true}
      maxLines={Infinity}
      readOnly={false}
      width='100%'
      style={{ width: '100%' }}
    />
    <button
      disabled={!dirtyBit || isSaving}
      onClick={() => saveStarterCode()}
      style={{ width: 100 }}
      className="mt-2 btn btn-primary">{buttonTxt}
    </button>

  </div>
}

const AssnEditorPrompt = ({ serverAssnData, courseId, assnId }) => {
  const promptPath = `assns/${courseId}/assignments/${assnId}/docs/prompt`
  console.log(promptPath)
  return <div className='d-flex flex-column mt-3 w-100' >
    <h3>Prompt</h3>
    <TipTap
      editable={true}
      firebaseDocPath={promptPath}
      buttonBar={AssnEditorButtonBar}
    />
  </div>
}

const AssnEditorMeta = ({ serverAssnData, setTypeCache, courseId, assnId }) => {

  
  const assnPath = `assns/${courseId}/assignments/${assnId}`
  const imgPath = `assignments/${courseId}/${assnId}/icon`
  const [title, setTitle] = useState(serverAssnData.metaData.title)
  const [assnType, setAssnType] = useState(serverAssnData.metaData.type)
  const [dirtyBit, setDirtyBit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)



  function updateTitle(newTitle) {
    console.log('update title')
    setTitle(newTitle)
    setDirtyBit(true)
  }

  function updateType(newType) {
    console.log('update type')
    setAssnType(newType)
    setDirtyBit(true)
  }

  function saveMetaData() {
    console.log('save metadata', title)
    setIsSaving(true)
    updateMetaData(courseId, assnId, {
      title: title,
      type: assnType
    }, () => {
      setDirtyBit(false)
      setIsSaving(false)
      setTypeCache(assnType)
    })
  }

  let buttonTxt = isSaving ? 'Saving...' : 'Save'
  buttonTxt = dirtyBit ? 'Save' : 'Saved'
  return <div className="form-group">
    <h3>Meta Data</h3>


    <b >Assignment Title:</b>
    <input type="text" className="form-control" placeholder="Enter title"
      value={title}
      onChange={(e) => { updateTitle(e.target.value) }} />




    <b className="mt-2">Assignment Type:</b>
    <DropdownButton title={assnType} variant="light">
      <Dropdown.Item onClick={() => updateType('karel')}>karel</Dropdown.Item>
      <Dropdown.Item onClick={() => updateType('console')}>console</Dropdown.Item>
      <Dropdown.Item onClick={() => updateType('graphics')}>graphics</Dropdown.Item>
    </DropdownButton>

    <b className="mt-2">Assignment Image:</b><br />
    <EditableImage
      firebaseDocPath={assnPath}
      imageStoragePath={imgPath}
      editable={true}
      width='150px'
      height='100px'
    />



    <button
      disabled={!dirtyBit || isSaving}
      onClick={() => saveMetaData()}
      style={{ width: 100 }}
      className="mt-2 btn btn-primary">{buttonTxt}
    </button>
  </div>
}

// if an assnId doesn't exist, create it!
export async function createNewAssignment(
  courseId,
  assnId,
  onCreate
) {
  const metaData = {
    title: "Untitled",
    type: 'graphics'
  };
  updateMetaData(courseId, assnId, metaData, onCreate);
}

async function updateMetaData(
  courseId,
  assnId,
  metaData,
  callbackFn
) {
  const db = getFirestore();
  const path = `assns/${courseId}/assignments/${assnId}`
  console.log('updateMetaData', path)
  const assnRef = doc(db, path);
  await setDoc(assnRef, metaData, { merge: true });
  callbackFn({ metaData })
}

async function updateStarterCode(
  courseId,
  assnId,
  starterCode,
  callbackFn
) {
  const db = getFirestore();
  const assnRef = doc(db, `assns/${courseId}/assignments/${assnId}/docs/starterCode`);
  await setDoc(assnRef, { 'main.py': starterCode }, { merge: true });
  callbackFn()
}


