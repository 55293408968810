import { MonacoBinding } from "./y/YMonaco";
import * as Y from "yjs";
import { User } from "../../firebase/models";
import { createMutex } from "lib0/mutex";
import { WebrtcProvider } from "./y/YWebrtc";
import { FirestoreCodeLogger } from "./fire/FirestoreLogger";
import { MonacoBindingSafe } from "./fire/FireMonaco";
import { Collab } from "./hooks/utils";

const WEBRTC_PROVIDERS = [
    // "wss://y-webrtc.codeinplace.org", 
    // 'wss://cip-webrtc-fdbf6d23b7e2.herokuapp.com'
    'wss://test-cip-webrtc-ddfdf127262b.herokuapp.com'
]

export class CollabSession {
    public  readonly ydoc: Y.Doc;
    public readonly provider: WebrtcProvider;

    private binding: MonacoBinding | MonacoBindingSafe;
    private mux: any;
    private onUpdate: any;
    private yDocInitialized: boolean = false;
    private firestoreLogger: FirestoreCodeLogger;
    private lastUpdateCode: string = "";
    private onStreamRecieved: any;


    constructor (
        roomName: string,
        user: any,
        onUpdate: any,
        projectData: any,
        isTeachNow: boolean = false
    ) {
        const userData = {
            database_user_id: user.uid,
            name: user.displayName,
            color: generateRandomDarkColorHex()
        }
        this.ydoc = new Y.Doc();
        this.onStreamRecieved = (data: any) => {
            if("clientId" in data && data.clientId === this.ydoc.clientID) { return; }
            onUpdate(data);
        };
        this.provider = new WebrtcProvider(
            roomName, 
            this.ydoc, 
            {signaling:isTeachNow ? WEBRTC_PROVIDERS: []},
            this.onStreamRecieved, 
            4);
        this.mux = createMutex();
        this.firestoreLogger = new FirestoreCodeLogger(this);
        this.onUpdate = onUpdate;

        onUpdate({key: Collab.CLIENT_ID, data: this.ydoc.clientID, clientId: this.ydoc.clientID});


        if(projectData && projectData.ydoc) {
            const ydocArray = projectData.ydoc.toUint8Array();
            Y.applyUpdateV2(this.ydoc, ydocArray);
            this.yDocInitialized = true;
        }

        this.provider.awareness.setLocalStateField("user", userData);
        this.provider.awareness.on("update", this.onAwarenessUpdate.bind(this));
    }


    stream(eventKey: string, data: any) {
        this.provider.room.stream({
            key: eventKey,
            data: data,
            clientId: this.ydoc.clientID
        })
    }



    onAwarenessUpdate({ added, updated, removed }) { 
        // If client added update collab list
        added.forEach(clientId => { 
            const state = this.provider.awareness.getStates().get(clientId);

            const info = {
                clientId: clientId,
                userData: state?.user
            }
            this.onUpdate("joined", info);
        });

        updated.forEach(clientId => {
            if(clientId === this.ydoc.clientID) { return; }
            const state = this.provider.awareness.getStates().get(clientId)
            if(!state) { return; }
            //print type of clientId
            for(let key of Object.keys(state)) {
                if(key !== "user") { 
                    this.onUpdate(key, state[key]);
                }
            }
        });
      
        // Remove user from collab list
        removed.forEach(clientId => {
            this.onUpdate("left", clientId);
        });

    }



    setMonacoBinding(currFileId: string, monacoModel: any, monacoEditors: any, serverData: any, firestorePath: string, isFallback: boolean = false) {
        if(this.binding) {
            this.binding.destroy();
        }
        if(isFallback) {
            this.binding = new MonacoBindingSafe(this.ydoc.getText(currFileId), monacoModel, monacoEditors, firestorePath, this);
        } else {
            this.binding = new MonacoBinding(this.ydoc.getText(currFileId), monacoModel, monacoEditors, this.provider.awareness);
            if(this.provider) {
                this.provider.awareness.setLocalStateField("file", currFileId);
            }
        }

        if(!this.yDocInitialized) {
            let content = serverData?.content;
            // if content is undefined, set it to an empty string
            if (!content) {
                content = "";
            }
            this.ydoc.getText(currFileId).insert(0, content);
            this.logCode(content, firestorePath);
            monacoModel.setValue(content);
        }
    }


    logCode(debouncedChanges: any, firestorePath: string) {
        this.mux(() => {
            this.firestoreLogger.logCode(debouncedChanges, firestorePath, this.binding instanceof MonacoBindingSafe)
        })
    }

    getLastUpdateCode() {
        return this.lastUpdateCode;
    }

    setLastUpdateCode(code: string) {  
        this.lastUpdateCode = code;
    }


    destroyBinding() {
        if(!this.binding) { return; }
        this.binding.destroy();
    }



    destroy() {
        if(this.provider) { 
            this.provider.destroy();
        }
        if(this.binding) { this.binding.destroy(); }
    }
    

}


function generateRandomDarkColorHex() {
    const colors = [
        "red",
        "blue",
        "green",
        "purple",
        "orange",
        "yellow",
    ]
    return colors[Math.floor(Math.random()*6)]
}