import React from 'react'
import './footer.css'

import { Link } from "react-scroll";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='row'>
        <div className='col-sm-1'></div>
        <div className="brand-name col-sm-3">
          <p className="text-center first-line" style={{fontFamily: 'Cinzel', fontSize: '4rem', margin: '0'}}>CS Bridge</p>
          <p className="text-center second-line" style={{fontFamily: 'Cinzel',fontSize: '1rem', margin: '0', color: 'rgb(186, 0, 0)'}}>Stanford University</p>
        </div>
        <div className='footer-links1 col-sm-2'>
          <p><Link activeClass="active" smooth spy to="csb-about" duration={100} style={{ cursor: 'pointer' }}>About</Link></p>
          <p><Link activeClass="active" smooth spy to="csb-courses" duration={100} style={{ cursor: 'pointer' }}>Courses</Link></p>
          <p><Link activeClass="active" smooth spy to="csb-projects" duration={100} style={{ cursor: 'pointer' }}>Project</Link></p>
          
        </div>
        <div className='footer-links2 col-sm-2'>
          <p><Link activeClass="active" smooth spy to="csb-news" duration={100} style={{ cursor: 'pointer' }}>News</Link></p>
        </div>
        <div className='contact-detail col-sm-3' style={{fontSize: '1rem', textAlign: 'left'}}>
          <p style={{fontSize: '1rem', textAlign: 'left'}}>CS Bridge - Gates Computer <br /> 
          Science, 353 Jane Stanford Way, <br />
           Stanford University, CA 94305</p>
          <p style={{fontSize: '1rem', textAlign: 'left'}}>csbridge@cs.stanford.edu</p>
        </div>
        <div className='col-sm-1'></div>
        <div className='row'>
          <div className='col-sm-1'></div>
          <div className='col-sm-10'>
            <hr className='footer-hr' style={{ borderTop: "4px solid", color: 'rgb(186, 0, 0)' }} />
          </div>
          <div className='col-sm-1'></div>
        </div>
        <div className='row'>
          <div className='col-sm-1'></div>
          <div className='col-sm-7'>
            <p style={{fontSize: '1rem', textAlign: 'left'}}>CS BRIDGE IS A PROGRAM OF THE COMPUTER SCIENCE DEPARTMENT AT STANFORD UNIVERSITY</p>
          </div>
          <div className='col-sm-3'>
            <p style={{fontSize: '1rem', textAlign: 'right'}}>© 2023 CS Bridge</p>
          </div>
          <div className='col-sm-1'></div>
        </div>
      </div>
    </div>
  )
}

export default Footer