import { createNewAssignment } from "course/assnEditor/AssnEditor"
import { SortableAssnTable } from "course/assnEditor/SortableAssnTable"
import { AssnContext } from "course/contexts/AssnContext"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext, useEffect } from "react"
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa"
import { Link, useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { ExamplesContext } from "../contexts/ExamplesContext"
import { HandoutsContext } from "../contexts/HandoutsContext"
import { SortableHandoutTable } from "./SortableHandoutTable"

export const HandoutEditor = () => { 
  const { courseId } = useParams()
  const navigate = useNavigate()

  const { handouts, handoutsLoading } = useContext(HandoutsContext)

  

  if (handoutsLoading) return <></>



 

  return <div className="container-lg mt-3">
    <SortableHandoutTable 
      handouts={handouts}
      onOrderChange={(handouts) => {saveHandouts(handouts, courseId)}}
      deleteHandout={(index) => {deleteHandout(handouts, courseId, index)}}
      renameHandout={(index) => {renameHandout(handouts, courseId, index)}}
    />
    <button className="btn btn-primary btn-sm" onClick={() => addHandout(handouts, courseId)}><FaPlus /> New Handout</button>
    <hr/>
    <div style={{height:50}}/>
    
  </div>
}

const UnlistedAssignmentsTable= ()=>{
  const courseId = useCourseId()
  const { assnMetaData } = useContext(AssnContext)

  console.log(assnMetaData)
  return <>
  <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">All Assignments and Examples</div>
  
  {
    Object.keys(assnMetaData).map((assnId)=>{
      const editUrl = `/${courseId}/assnEditor/${assnId}`
      const title = assnMetaData[assnId].title
      return <><Link to={editUrl}>{title} ({assnId})</Link><br/></>
    })
  }
  <div style={{height:50}}/>
  </>
}


async function addHandout(
  handouts,
  courseId
) {
  
  const { value: handoutTitle } = await Swal.fire({
    title: "New Handout",
    input: "text",
    inputLabel: "What is your handout title?",
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return "You can't have an empty title";
      }
    }
  });
  // get the name via a swal
  const { value: handoutUid } = await Swal.fire({
    title: "New Handout",
    input: "text",
    inputLabel: "What is your handout uid?",
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return "You can't have an empty uid";
      }
    }
  });

  handouts.push({
    uid: handoutUid,
    title: handoutTitle,
  });

  const handoutRef = getHandoutsRef(courseId)
  await setDoc(handoutRef, { handouts }, { merge: true });

}

function getHandoutsRef(courseId) {
  const db = getFirestore();
  return doc(db, `course/${courseId}/handouts/doc`);
}

async function deleteHandout(
  handouts,
  courseId,
  handoutIndex
) {
  await Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this section!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it"
  }).then(async (result) => {
    if (result.isConfirmed) {
      handouts.splice(handoutIndex, 1);
      const db = getFirestore();
      const handoutRef = getHandoutsRef(courseId)
      await setDoc(handoutRef, { handouts }, { merge: true });
      Swal.fire("Deleted!", "Your section has been deleted.", "success");
    }
  })

}
async function renameHandout(
  handouts,
  courseId,
  assnIndex
) {
  const { value: newTitle } = await Swal.fire({
    title: 'Rename Section',
    input: 'text',
    inputValue: handouts[assnIndex].title,
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return 'You need to write something!'
      }
    }
  })
  handouts[assnIndex].title = newTitle
  const handoutRef = getHandoutsRef(courseId)
  await setDoc(handoutRef, { handouts }, { merge: true });
}

async function setSectionVisibility(
  assns,
  courseId,
  assnIndex,
  newVisibility
) {
  assns[assnIndex].isVisible = newVisibility
  const db = getFirestore();
  const assnRef = doc(db, ``);
  await setDoc(assnRef, { assns }, { merge: true });
}

async function addExample(
  examples,
  courseId,
  callback
) {

  const { value: newAssnId } = await Swal.fire({
    title: "New Assignment!",
    input: "text",
    inputLabel: "What is your assignment id",
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return "You can't have an empty assnId!";
      }
    },
  });

  if(!(newAssnId in assnMetaData)) {
    await createNewAssignment(
      courseId,
      newAssnId, () => { }
    )
  }
  

  const insert = assns
  const newList = assns[assnIndex].assnList
  newList.push(newAssnId)
  assns[assnIndex].assnList = newList

  const db = getFirestore();
  const assnRef = doc(db, ``);
  await setDoc(assnRef, { assns: insert }, { merge: true });
  callback(assns, newAssnId)
}


async function saveHandouts(
  handouts,
  courseId
) {
  const db = getFirestore();
  const handoutRef = doc(db, `course/${courseId}/handouts/doc`);
  await setDoc(handoutRef, { handouts }, { merge: true });
}