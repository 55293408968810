import { collection, doc, getDoc, getFirestore, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";

/**
 * Assignments are reactive to the firebase, so if you change
 * the value there, everyones assignment list will update!
 */

// lessons documentation is here:
// https://docs.google.com/document/d/14tDLbvJIJL6ftA9_xBqwawas_1klCLhrPVY_6WwyX5c/edit

const defaultData = {
  workedExamples:[],
  examplesLoading: true,
  examplesError: null
};

export const ExamplesContext = React.createContext(defaultData);
export const ExamplesProvider = ({ courseId, children }) => {

  const db = getFirestore()
  const docRef = doc(db, `course/${courseId}/workedexamples/doc`);  
  const [workedExamples, examplesLoading, examplesError] = useDocumentData(docRef)


  let examplesSafe = workedExamples?.examplesList || []

  return (
    <ExamplesContext.Provider
      value={{
        workedExamples:examplesSafe,
        examplesLoading,
        examplesError
      }}
    >
      {children}
    </ExamplesContext.Provider>
  );
};
